<div class="subscription-details">
  <app-card class="subscription-details__addons-multiple">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="default-s semibold subscription-details__subtitle">
        {{ 'general.agents_l' | translate }}
      </div>
      <div class="counter-container">
        <span
          class="counter decrement-button"
          [ngClass]="{
            disabled: disabled || (additionalAgentsModule === 0 && !isAdmin)
          }"
          (click)="onRemoveAgent()"
        >
          -
        </span>
        <span class="counter-amount">
          {{
            totalAgentsCount + totalFreeAgentsCount <=
            agentsAmount + freeAgentsAmount
              ? agentsAmount + freeAgentsAmount
              : totalAgentsCount + totalFreeAgentsCount
          }}
        </span>
        <span
          class="counter increment-button"
          [ngClass]="{ disabled: disabled }"
          (click)="onAddAgent()"
        >
          +
        </span>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <h3 class="title-m subscription-details__title">
        @if (isAdmin) {
          {{
            totalAgentsCount <= agentsAmount ? agentsAmount : totalAgentsCount
          }}
          {{
            (totalAgentsCount === 1 ? 'general.agent_l' : 'general.agents_l')
              | translate
          }}
          @if (totalAgentsCount > 1) {
            <span>{{ 'general.free_agent_l' | translate }}</span>
          }
        } @else {
          {{
            totalAgentsCount + totalFreeAgentsCount <=
            agentsAmount + freeAgentsAmount
              ? agentsAmount + freeAgentsAmount
              : totalAgentsCount + totalFreeAgentsCount
          }}
          {{
            (totalAgentsCount + totalFreeAgentsCount === 1
              ? 'general.agent_l'
              : 'general.agents_l'
            ) | translate
          }}
          @if (totalAgentsCount + totalFreeAgentsCount > 1) {
            <span>{{
              'general.including_free_agents_l'
                | translate: { count: totalFreeAgentsCount + 1 }
            }}</span>
          }
        }
      </h3>
      <div class="title-m subscription-details__price">
        <span>
          @if (isAdmin) {
            {{ getAgentsPrice() | currency: currency }}
          } @else {
            {{ getAgentsPrice(true) | currency: currency }}
          }
        </span>
        <span
          >/{{
            (isYearlySelected ? 'general.year_l' : 'general.month_l')
              | translate
          }}</span
        >
        <div class="gross-price-info">
          {{ 'addons.price_excluded_vat_l' | translate }}
        </div>
      </div>
    </div>
    <div class="mb10">
      <ng-container
        [ngTemplateOutlet]="renewTemplate"
        [ngTemplateOutletContext]="{
          value: isAdmin ? renewedAgents : renewedAgents + renewedFreeAgents
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="expireTemplate"
        [ngTemplateOutletContext]="{
          value: isAdmin ? expireAgents : expireAgents + expireFreeAgents
        }"
      ></ng-container>

      <ng-template #renewTemplate let-value="value">
        @if (value > 0) {
          <app-badge [color]="'light-green'" [borderStyle]="'none'">
            {{
              (value === 1
                ? 'general.renews_agent_l'
                : 'general.renews_agents_l'
              ) | translate: { value: value }
            }}
            {{ dueDate ? (dueDate | appDateTime) : '...' }}
          </app-badge>
        }
      </ng-template>
      <ng-template #expireTemplate let-value="value">
        @if (value > 0) {
          <app-badge [color]="'light-red'" [borderStyle]="'none'">
            {{
              (value === 1
                ? 'general.expires_agent_l'
                : 'general.expires_agents_l'
              ) | translate: { value: value }
            }}
            {{ dueDate ? (dueDate | appDateTime) : '...' }}
          </app-badge>
        }
      </ng-template>
    </div>
    <div class="d-flex justify-content-between">
      <p class="title-s subscription-details__description">
        {{ agentAddon?.description | translate }}
      </p>
    </div>
    @if (isAdmin && agentAddon && agentDiscount) {
      <div class="admin-discount-section">
        <div class="row">
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  @if (!isExpired(agentDiscount)) {
                    <div>{{ 'admin.addon_discount_end_l' | translate }}</div>
                  }
                  @if (isExpired(agentDiscount)) {
                    <div class="font-red">
                      {{ 'admin.addon_discount_end_expired_l' | translate }}
                    </div>
                  }
                </div>
                <div class="input-group mb10">
                  <app-date
                    [ngClass]="{ expired: isExpired(agentDiscount) }"
                    [ngModel]="agentDiscount.endDate"
                    (ngModelChange)="
                      onDiscountDateChange($event, agentDiscount)
                    "
                    [minDate]="minDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  <div>{{ 'admin.addon_discount_l' | translate }}</div>
                </div>
                <div class="input-group mb10">
                  <input
                    appInput
                    type="number"
                    [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                    [min]="ADDON_DISCOUNT_CONFIG.minValue"
                    [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                    class="form-control"
                    [ngModel]="agentDiscount.value"
                    [disabled]="agentDiscount?.endDate?.length === 0"
                    [required]="agentDiscount?.endDate?.length > 0"
                    (ngModelChange)="
                      onDiscountValueChange($event, agentDiscount)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col payment-details__section">
            <div class="col-12 pl0">
              <div class="d-flex flex-row">
                <div>
                  {{ 'admin.addon_discount_final_price_l' | translate }}
                </div>
              </div>
              <div>
                @if (!recalculatingPrice) {
                  <span
                    >{{ getAgentsPrice(true) | currency: currency }}/{{
                      (isYearlySelected ? 'general.year_l' : 'general.month_l')
                        | translate
                    }}</span
                  >
                }
                @if (recalculatingPrice) {
                  <span>...</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </app-card>

  @if (isAdmin) {
    <ng-container [ngTemplateOutlet]="freeAgent"></ng-container>
  }

  <app-card class="subscription-details__addons-multiple">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="default-s semibold subscription-details__subtitle">
        <span>{{ 'general.homepageModules_l' | translate }}</span>
      </div>
      <div class="counter-container">
        <span
          class="counter decrement-button"
          [ngClass]="{
            disabled: disabled || (additionalHomepageModules === 0 && !isAdmin)
          }"
          (click)="onRemoveHomepageModule()"
        >
          -
        </span>
        <span class="counter-amount"> {{ homepageModuleAmount }} </span>
        <span
          class="counter increment-button"
          [ngClass]="{ disabled: disabled }"
          (click)="onAddHomepageModule()"
        >
          +
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <h3 class="title-m subscription-details__title">
        {{ homepageModuleAmount }}
        {{
          (homepageModuleAmount === 1
            ? 'general.homepageModule_l'
            : 'general.homepageModules_l'
          ) | translate
        }}
      </h3>
      <div class="title-m subscription-details__price">
        <span>
          @if (isAdmin) {
            {{ getHomepageModulePrice() | currency: currency }}
          } @else {
            {{ getHomepageModulePrice(true) | currency: currency }}
          }
        </span>
        <span
          >/{{
            (isYearlySelected ? 'general.year_l' : 'general.month_l')
              | translate
          }}</span
        >
        <div class="gross-price-info">
          {{ 'addons.price_excluded_vat_l' | translate }}
        </div>
      </div>
    </div>
    <div class="mb10">
      @if (renewedHomepageModules) {
        <app-badge [color]="'light-green'" [borderStyle]="'none'">
          {{
            (renewedHomepageModules === 1
              ? 'general.renews_agent_l'
              : 'general.renews_agents_l'
            ) | translate: { value: renewedHomepageModules }
          }}
          {{ dueDate ? (dueDate | appDateTime) : '...' }}
        </app-badge>
      }
      @if (expireHomepageModules) {
        <app-badge [color]="'light-red'" [borderStyle]="'none'">
          {{
            (expireHomepageModules === 1
              ? 'general.expires_agent_l'
              : 'general.expires_agents_l'
            ) | translate: { value: expireHomepageModules }
          }}
          {{ dueDate ? (dueDate | appDateTime) : '...' }}
        </app-badge>
      }
    </div>
    <div class="d-flex justify-content-between">
      <p
        class="title-s subscription-details__description"
        [innerHTML]="homepageModuleAddon?.description | translate"
      ></p>
    </div>
    @if (isAdmin && homepageModuleAddon && homepageModuleDiscount) {
      <div class="admin-discount-section">
        <div class="row">
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  @if (!isExpired(homepageModuleDiscount)) {
                    <div>{{ 'admin.addon_discount_end_l' | translate }}</div>
                  }
                  @if (isExpired(homepageModuleDiscount)) {
                    <div class="font-red">
                      {{ 'admin.addon_discount_end_expired_l' | translate }}
                    </div>
                  }
                </div>
                <div class="input-group mb10">
                  <app-date
                    [ngClass]="{ expired: isExpired(homepageModuleDiscount) }"
                    [ngModel]="homepageModuleDiscount.endDate"
                    (ngModelChange)="
                      onDiscountDateChange($event, homepageModuleDiscount)
                    "
                    [minDate]="minDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  <div>{{ 'admin.addon_discount_l' | translate }}</div>
                </div>
                <div class="input-group mb10">
                  <input
                    appInput
                    type="number"
                    [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                    [min]="ADDON_DISCOUNT_CONFIG.minValue"
                    [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                    class="form-control"
                    [ngModel]="homepageModuleDiscount.value"
                    [disabled]="homepageModuleDiscount?.endDate?.length === 0"
                    [required]="homepageModuleDiscount?.endDate?.length > 0"
                    (ngModelChange)="
                      onDiscountValueChange($event, homepageModuleDiscount)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col payment-details__section">
            <div class="col-12 pl0">
              <div class="d-flex flex-row">
                <div>
                  {{ 'admin.addon_discount_final_price_l' | translate }}
                </div>
              </div>
              <div>
                @if (!recalculatingPrice) {
                  <span
                    >{{ getHomepageModulePrice(true) | currency: currency }}/{{
                      (isYearlySelected ? 'general.year_l' : 'general.month_l')
                        | translate
                    }}</span
                  >
                }
                @if (recalculatingPrice) {
                  <span>...</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </app-card>

  <app-card class="subscription-details__addons-multiple">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="default-s semibold subscription-details__subtitle">
        {{ 'general.customerCooperations_l' | translate }}
      </div>
      <div class="counter-container">
        <div class="d-flex justify-content-end align-items-end ps-5">
          <span
            class="counter decrement-button"
            [ngClass]="{
              disabled:
                disabled || (additionalCustomerCooperations === 0 && !isAdmin)
            }"
            (click)="onRemoveCustomerCooperation()"
          >
            -
          </span>
          <span class="counter-amount">{{ customerCooperationsAmount }}</span>
          <span
            class="counter increment-button"
            [ngClass]="{ disabled: disabled }"
            (click)="onAddCustomerCooperation()"
          >
            +
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <h3 class="title-m subscription-details__title">
        {{ customerCooperationsAmount }}
        {{
          (customerCooperationsAmount === 1
            ? 'general.customerCooperation_l'
            : 'general.customerCooperations_l'
          ) | translate
        }}
      </h3>
      <div class="title-m subscription-details__price">
        <span>
          @if (isAdmin) {
            {{ getCustomerCooperationPrice() | currency: currency }}
          } @else {
            {{ getCustomerCooperationPrice(true) | currency: currency }}
          }
        </span>
        <span
          >/{{
            (isYearlySelected ? 'general.year_l' : 'general.month_l')
              | translate
          }}</span
        >
        <div class="gross-price-info">
          {{ 'addons.price_excluded_vat_l' | translate }}
        </div>
      </div>
    </div>
    <div class="mb10">
      @if (renewedCustomerCooperations) {
        <app-badge [color]="'light-green'" [borderStyle]="'none'">
          {{
            (renewedCustomerCooperations === 1
              ? 'general.renews_agent_l'
              : 'general.renews_agents_l'
            ) | translate: { value: renewedCustomerCooperations }
          }}
          {{ dueDate ? (dueDate | appDateTime) : '...' }}
        </app-badge>
      }
      @if (expireCustomerCooperations) {
        <app-badge [color]="'light-red'" [borderStyle]="'none'">
          {{
            (expireCustomerCooperations === 1
              ? 'general.expires_agent_l'
              : 'general.expires_agents_l'
            ) | translate: { value: expireCustomerCooperations }
          }}
          {{ dueDate ? (dueDate | appDateTime) : '...' }}
        </app-badge>
      }
    </div>
    <div class="d-flex justify-content-between">
      <p
        class="title-s subscription-details__description"
        [innerHTML]="customerCooperationAddon?.description | translate"
      ></p>
    </div>
    @if (isAdmin && customerCooperationAddon && customerCooperationDiscount) {
      <div class="admin-discount-section">
        <div class="row">
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  @if (!isExpired(customerCooperationDiscount)) {
                    <div>{{ 'admin.addon_discount_end_l' | translate }}</div>
                  }
                  @if (isExpired(customerCooperationDiscount)) {
                    <div class="font-red">
                      {{ 'admin.addon_discount_end_expired_l' | translate }}
                    </div>
                  }
                </div>
                <div class="input-group mb10">
                  <app-date
                    [ngClass]="{
                      expired: isExpired(customerCooperationDiscount)
                    }"
                    [ngModel]="customerCooperationDiscount.endDate"
                    (ngModelChange)="
                      onDiscountDateChange($event, customerCooperationDiscount)
                    "
                    [minDate]="minDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  <div>{{ 'admin.addon_discount_l' | translate }}</div>
                </div>
                <div class="input-group mb10">
                  <input
                    appInput
                    type="number"
                    [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                    [min]="ADDON_DISCOUNT_CONFIG.minValue"
                    [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                    class="form-control"
                    [ngModel]="customerCooperationDiscount.value"
                    [disabled]="
                      customerCooperationDiscount?.endDate?.length === 0
                    "
                    [required]="
                      customerCooperationDiscount?.endDate?.length > 0
                    "
                    (ngModelChange)="
                      onDiscountValueChange($event, customerCooperationDiscount)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col payment-details__section">
            <div class="col-12 pl0">
              <div class="d-flex flex-row">
                <div>
                  {{ 'admin.addon_discount_final_price_l' | translate }}
                </div>
              </div>
              <div>
                @if (!recalculatingPrice) {
                  <span
                    >{{
                      getCustomerCooperationPrice(true) | currency: currency
                    }}/{{
                      (isYearlySelected ? 'general.year_l' : 'general.month_l')
                        | translate
                    }}</span
                  >
                }
                @if (recalculatingPrice) {
                  <span>...</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </app-card>

  @if (featureAddons && featureAddons.length) {
    <app-card class="subscription-details__addons">
      <h3 class="default-s semibold subscription-details__subtitle">
        {{ 'subscription.select_addons_l' | translate }}
      </h3>
      @for (addon of featureAddons; track addon.id) {
        @if (isAddonVisible(addon)) {
          <app-addon
            [isAdmin]="isAdmin"
            [disabled]="disabled || (!isAdmin && isBooked(addon))"
            [dueDate]="dueDate"
            [addon]="addon"
            [addonDiscount]="getAddonDiscount(addon)"
            [price]="getAddonPrice(addon)"
            [isSelected]="isSelected(addon)"
            [currency]="currency"
            [recalculatingPrice]="recalculatingPrice"
            [isYearlySelected]="isYearlySelected"
            [tooltip]="
              !isAdmin && isBooked(addon)
                ? ('subscription-modules.contact.support_tooltip_l' | translate)
                : ''
            "
            (toggleAddon)="onToggleAddon($event)"
            (discountChange)="onDiscountChange($event)"
            class="subscription-details__addons-list"
          >
            @if (isAdmin && isDigitalContractBooked(addon)) {
              <div>
                <div class="mb30">
                  {{ 'admin.digital_contract.quota_discount_l' | translate }}
                </div>
                @for (d of digitalContractQuotaPackages; track d.id) {
                  <div class="mb20">
                    <div class="row">
                      <!-- Show column with quota package -->
                      <div class="col">
                        <div class="row mr0 ml0">
                          <span
                            >{{
                              'admin.digital_contract.quota_size_l' | translate
                            }}:</span
                          >&nbsp;<span>{{ d.quantity }}</span>
                        </div>
                        <div class="row mr0 ml0">
                          <span class="semibold">{{
                            d.price | currency: currency
                          }}</span
                          >&nbsp;<span>{{
                            'general.net_price_information_l' | translate
                          }}</span>
                        </div>
                        <div class="row mr0 ml0">
                          <span class="semibold">{{
                            getQuotaDiscountPrice(d) | currency: currency
                          }}</span
                          >&nbsp;<span>{{
                            'admin.addon_discount_final_price_l' | translate
                          }}</span>
                        </div>
                      </div>
                      <!-- Show column with quota package DISCOUNT -->
                      <div class="col">
                        <div class="input-group mb10">
                          <input
                            appInput
                            type="number"
                            [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                            [min]="ADDON_DISCOUNT_CONFIG.minValue"
                            [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                            [(ngModel)]="getQuotaDiscount(d.id).value"
                            class="form-control"
                            (ngModelChange)="onChangeValueDmv(d.id, $event)"
                          />
                        </div>
                        <div class="input-group mb10">
                          <app-date
                            [(ngModel)]="getQuotaDiscount(d.id).endDate"
                            [minDate]="minDate"
                            required
                            (ngModelChange)="changeDateDmv(d.id, $event)"
                          ></app-date>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div class="row mr0 ml0">
                  <div class="col">
                    <app-button (clickEvent)="onSaveQuotaDiscounts()"
                      >{{ 'general.save_a' | translate }}
                    </app-button>
                  </div>
                </div>
              </div>
            }
          </app-addon>
        }
      }
    </app-card>
  }
  @if (isAdmin && objectContingentQuotaPackages.length > 0) {
    <app-card class="subscription-details__addons-multiple pt-0 mb-2">
      <div class="section_top">
        <div class="d-flex justify-content-between">
          <h3 class="title-m subscription-details__title">
            {{ 'general.object_contingent_l' | translate }}
          </h3>
        </div>
        <div class="d-flex justify-content-between">
          <p
            class="title-s subscription-details__description"
            [innerHTML]="'admin.object_contingent.description' | translate"
          ></p>
        </div>
        <div>
          <div class="mb30 mt-4">
            {{ 'admin.object_contingent.quota_discount_l' | translate }}
          </div>
          @for (d of objectContingentQuotaPackages; track d.id) {
            <div class="mb20">
              <div class="row">
                <!-- Show column with quota package -->
                <div class="col">
                  <div class="row mr0 ml0">
                    <span
                      >{{
                        'admin.digital_contract.quota_size_l' | translate
                      }}:</span
                    >&nbsp;<span>{{ d.quantity }}</span>
                  </div>
                  <div class="row mr0 ml0">
                    <span class="semibold">{{
                      d.price | currency: currency
                    }}</span
                    >&nbsp;<span>{{
                      'general.net_price_information_l' | translate
                    }}</span>
                  </div>
                  <div class="row mr0 ml0">
                    <span class="semibold">{{
                      getObjectContingentQuotaDiscountPrice(d)
                        | currency: currency
                    }}</span
                    >&nbsp;<span>{{
                      'admin.addon_discount_final_price_l' | translate
                    }}</span>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mb10">
                    <input
                      appInput
                      type="number"
                      [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                      [min]="ADDON_DISCOUNT_CONFIG.minValue"
                      [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                      [(ngModel)]="getObjectContingentQuotaDiscount(d.id).value"
                      (ngModelChange)="onChangeValue(d.id, $event)"
                      class="form-control"
                    />
                  </div>
                  <div class="input-group mb10">
                    <app-date
                      [(ngModel)]="
                        getObjectContingentQuotaDiscount(d.id).endDate
                      "
                      [minDate]="minDate"
                      required
                      (ngModelChange)="changeDate(d.id, $event)"
                    ></app-date>
                  </div>
                </div>
              </div>
            </div>
          }
          <div class="row mr0 ml0">
            <div class="col">
              <app-button (clickEvent)="onSaveObjectContingentQuotaDiscounts()"
                >{{ 'general.save_a' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  }

  <ng-template #freeAgent>
    <app-card class="subscription-details__addons-multiple">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="default-s semibold subscription-details__subtitle">
          {{ 'general.free_agents_l' | translate }}
        </div>
        <div class="counter-container">
          <div class="d-flex justify-content-end align-items-end ps-5">
            <span
              class="counter decrement-button"
              (click)="onRemoveFreeAgent()"
            >
              -
            </span>
            <span class="counter-amount">
              {{
                totalFreeAgentsCount <= freeAgentsAmount
                  ? freeAgentsAmount
                  : totalFreeAgentsCount
              }}</span
            >
            <span
              class="counter increment-button"
              [ngClass]="{ disabled: disabled }"
              (click)="onAddFreeAgent()"
            >
              +
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h3 class="title-m subscription-details__title">
          {{
            totalFreeAgentsCount <= freeAgentsAmount
              ? freeAgentsAmount
              : totalFreeAgentsCount
          }}
          {{
            (totalFreeAgentsCount === 1
              ? 'subscription.free_agent_l'
              : 'subscription.free_agents_l'
            ) | translate
          }}
        </h3>
      </div>
      <div class="mb10">
        @if (renewedFreeAgents) {
          <app-badge [color]="'light-green'" [borderStyle]="'none'">
            {{
              (renewedFreeAgents === 1
                ? 'general.renews_agent_l'
                : 'general.renews_agents_l'
              ) | translate: { value: renewedFreeAgents }
            }}
            {{ dueDate ? (dueDate | appDateTime) : '...' }}
          </app-badge>
        }
        @if (expireFreeAgents) {
          <app-badge [color]="'light-red'" [borderStyle]="'none'">
            {{
              (expireFreeAgents === 1
                ? 'general.expires_agent_l'
                : 'general.expires_agents_l'
              ) | translate: { value: expireFreeAgents }
            }}
            {{ dueDate ? (dueDate | appDateTime) : '...' }}
          </app-badge>
        }
      </div>
      <div class="d-flex justify-content-between">
        <p class="title-s subscription-details__description">
          {{ freeAgentAddon?.description | translate }}
        </p>
      </div>
      <div class="admin-discount-section">
        <div class="row">
          <div class="col-4 payment-details__section">
            <div class="col-12 pl0">
              <div>
                <div class="d-flex flex-row">
                  @if (!isExpired(freeAgentDiscount)) {
                    <div>{{ 'admin.addon_discount_end_l' | translate }}</div>
                  }
                  @if (isExpired(freeAgentDiscount)) {
                    <div class="font-red">
                      {{ 'admin.addon_discount_end_expired_l' | translate }}
                    </div>
                  }
                </div>
                <div class="input-group mb10">
                  <app-date
                    [ngClass]="{ expired: isExpired(freeAgentDiscount) }"
                    [ngModel]="freeAgentDiscount.endDate"
                    [minDate]="minDate"
                  ></app-date>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </ng-template>
</div>
