import { Injectable } from '@angular/core';
import {
  Addon,
  AddonBasketItem,
  AddonType,
  Product,
  ProductDiff,
  ProductOverview
} from '@ui/shared/models';

@Injectable()
export class SubscriptionsDiffService {
  getProductsDiff(
    product: Product,
    addons: Addon[],
    customerProduct: ProductOverview // contains old state of product and addons
  ): ProductDiff {
    const purchasedProduct =
      customerProduct?.product?.id !== product?.id ? product.id : null;

    const discardedProduct = purchasedProduct
      ? customerProduct?.product?.id
      : null;

    const discardedAddons: AddonBasketItem[] = [];
    let purchasedAddons: AddonBasketItem[] = [];

    if (!purchasedProduct) {
      let customerAddons = customerProduct?.addons
        ? [...customerProduct.addons]
        : [];
      customerAddons = customerAddons.filter(addon => addon?.amounts?.renew);

      const customerAgentAddon = customerAddons.find(
        ca => ca.type === AddonType.AGENT
      );
      const customerFreeAgentAddon = customerAddons.find(
        ca => ca.type === AddonType.FREE_AGENT
      );
      const customerHomepageAddon = customerAddons.find(
        ca => ca.type === AddonType.HOMEPAGE_MODULE_REST
      );
      const customerCustomerCooperationAddon = customerAddons.find(
        ca => ca.type === AddonType.CUSTOMER_COOPERATION
      );

      addons.forEach(currentAddon => {
        let oldAddon = null;
        switch (currentAddon.type) {
          case AddonType.AGENT:
            oldAddon = customerAgentAddon;
            break;
          case AddonType.FREE_AGENT:
            oldAddon = customerFreeAgentAddon;
            break;
          case AddonType.HOMEPAGE_MODULE_REST:
            oldAddon = customerHomepageAddon;
            break;
          case AddonType.CUSTOMER_COOPERATION:
            oldAddon = customerCustomerCooperationAddon;
            break;
        }
        if (oldAddon) {
          if (currentAddon.amounts.expire > oldAddon.amounts.expire) {
            const newExpiring = Math.abs(
              currentAddon.amounts.expire - oldAddon.amounts.expire
            );
            discardedAddons.push({
              productAddonID: currentAddon.id,
              type: currentAddon.type,
              quantity: newExpiring
            });
          }
          if (currentAddon.amounts.renew > oldAddon.amounts?.renew) {
            const newAmount = Math.abs(
              currentAddon.amounts?.renew - oldAddon.amounts?.renew
            );
            purchasedAddons.push({
              productAddonID: currentAddon.id,
              type: currentAddon.type,
              quantity: newAmount
            });
          }
        }

        const customerAddonIndex = customerAddons.findIndex(
          a => a.id === currentAddon.id
        );
        if (customerAddonIndex < 0) {
          purchasedAddons.push({
            productAddonID: currentAddon.id,
            type: currentAddon.type,
            quantity: currentAddon.amounts.renew ?? 1
          });
        } else {
          customerAddons.splice(customerAddonIndex, 1);
        }
      });

      discardedAddons.push(
        ...customerAddons.map(addon => ({
          productAddonID: addon.id,
          type: addon.type,
          quantity: 1
        }))
      );
    } else {
      purchasedAddons = addons.map(addon => ({
        productAddonID: addon.id,
        type: addon.type,
        quantity: 1
      }));
    }
    const purchasedAddonTypes = purchasedAddons.map(
      (addon: AddonBasketItem) => addon.type
    );
    const discardedAddonTypes = discardedAddons.map(
      (addon: AddonBasketItem) => addon.type
    );

    return {
      purchasedProduct,
      discardedProduct,
      purchasedAddons,
      discardedAddons,
      purchasedAddonTypes,
      discardedAddonTypes
    };
  }
}
