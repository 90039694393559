import { Action } from '@ngrx/store';
import { PortalCredentials } from '@ui/shared/models';

export const FETCH_PORTALS = '[Portals] Fetch Portals';
export const FETCH_PORTALS_SUCCESS = '[Portals] Fetch Portals Success';
export const FETCH_PORTALS_FAIL = '[Portals] Fetch Portals Fail';

export class FetchPortals implements Action {
  readonly type = FETCH_PORTALS;
  constructor(public id: string) {}
}

export class FetchPortalsSuccess implements Action {
  readonly type = FETCH_PORTALS_SUCCESS;
  constructor(public portals: PortalCredentials[]) {}
}

export class FetchPortalsFail implements Action {
  readonly type = FETCH_PORTALS_FAIL;
  constructor(public error) {}
}

export type PortalAction =
  | FetchPortals
  | FetchPortalsSuccess
  | FetchPortalsFail;
