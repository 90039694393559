import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  Addon,
  AddonDiscount,
  CreateSubscriptionPayload,
  Customer,
  LandlordUser,
  PaymentMethod,
  Product,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from 'libs/components/legacy';
import { SubscriptionFormComponent } from './components/subscription-form/subscription-form.component';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
  standalone: true,
  imports: [SubscriptionFormComponent, TranslateModule, LoadingSpinnerComponent]
})
export class SubscriptionPageComponent implements OnChanges {
  public isTrial: boolean;

  @Input() products: Product[];
  @Input() addons: Addon[];

  @Input() customer: Customer;
  @Input() customerProduct: ProductOverview;
  @Input() customerSize: string;
  @Input() nextProductId: string;
  @Input() defaultProductId: string;

  @Input() loaded: boolean;
  @Input() recalculatingPrices: boolean;

  @Input() saving: boolean;

  @Input() loadingError: boolean;
  @Input() form: FormGroup;

  @Input() isAdmin: boolean;
  @Input() paymentMethod: PaymentMethod;
  @Input() userData: LandlordUser;
  @Input() hasActiveProduct: boolean;
  @Input() addonDiscounts: AddonDiscount[];
  @Input() digitalContractQuotaDiscounts: QuotaDiscount[] = [];
  @Input() digitalContractQuotaPackages: QuotaPackage[] = [];
  @Input() objectContingentQuotaDiscounts: QuotaDiscount[] = [];
  @Input() objectContingentQuotaPackages: QuotaPackage[] = [];
  @Input() recalculationActionState$: Observable<ActionState>;
  @Output() saveQuotaDiscounts = new EventEmitter<QuotaDiscount[]>();
  @Output() saveObjectContingentQuotaDiscounts = new EventEmitter<
    QuotaDiscount[]
  >();
  @Output() discountChange = new EventEmitter<AddonDiscount>();
  @Output() productChange = new EventEmitter<string>();
  @Output() update = new EventEmitter<UpdateSubscriptionsPayload>();
  @Output() create = new EventEmitter<CreateSubscriptionPayload>();
  @Output() createLandlord = new EventEmitter();

  public currency = 'EUR';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerProduct) {
      const customerProduct = changes.customerProduct.currentValue;
      this.isTrial = customerProduct?.trial ?? true;
    }
  }

  onProductChange(id: string) {
    this.productChange.emit(id);
  }

  onUpdate(data: UpdateSubscriptionsPayload) {
    this.update.emit(data);
  }

  onCreate(data: CreateSubscriptionPayload) {
    this.create.emit(data);
  }

  onDiscountChange(discounts: AddonDiscount) {
    this.discountChange.emit(discounts);
  }

  onSaveQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveQuotaDiscounts.emit(discounts);
  }

  onSaveObjectContingentQuotaDiscounts(discounts: QuotaDiscount[]) {
    this.saveObjectContingentQuotaDiscounts.emit(discounts);
  }

  onCreateLandlord() {
    this.createLandlord.emit();
  }
}
