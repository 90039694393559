import { createSelector } from '@ngrx/store';

import { getPriosetState } from '../reducers';
import * as fromPriosetsReducers from './priosets.reducers';

export const getLoadPropertyActionState = createSelector(
  getPriosetState,
  fromPriosetsReducers.getLoadPropertyActionState
);

export const getPriosetLoading = createSelector(
  getLoadPropertyActionState,
  state => state.pending
);
export const getPriosetLoaded = createSelector(
  getLoadPropertyActionState,
  state => state.done
);
export const getPriosetError = createSelector(
  getLoadPropertyActionState,
  state => state.error
);

export const getLatestPriosetSaved = createSelector(
  getPriosetState,
  fromPriosetsReducers.getLatestPriosetSaved
);
