import { gql } from 'apollo-angular';
import {
  Customer,
  FilterOptions,
  Invoice,
  LandlordUser,
  Pagination,
  PortalCredentials,
  PropertySearcherUserBean
} from '@ui/shared/models';
import { paginationQuery } from 'libs/queries/pagination.query';

export interface UsersListResult {
  getUsers: {
    nodes: LandlordUser[];
    page: Pagination;
  };
  getCustomers: {
    nodes: Customer[];
    page: Pagination;
  };
  getInvoices: {
    nodes: Invoice[];
    page: Pagination;
  };
  getPropertySearcherUsers: {
    nodes: PropertySearcherUserBean[];
    page: Pagination;
  };
  activatePropertySearcher: {
    email: string;
  };
  changeLandlordUserEnabled: {
    id: string;
    enabled: boolean;
  };
  getCustomerById: {
    customer: Customer;
  };
  getInvoiceById: {
    invoice: Invoice;
  };
  userImpersonation: {
    access_token: string;
    session_state: string;
    realm: string;
    endpoint: string;
    client: string;
  };
  deactivateProductRenew: {
    message?: string;
  };
  deleteCustomer: {
    status: string;
    statusText: string;
  };
}

export const getUsers = gql`
  query getUsers($input: FilterOptions) {
    getUsers(input: $input) {
      page {
        ${paginationQuery}
      }
      nodes {
        id
        email
        enabled
        userPermissionRoleName
        profile
        lastLogin
        customer {
          id
          name
          customerIdent
        }
      }
    }
  }
`;

export const getCustomers = gql`
  query getCustomers($input: CustomerInput) {
    getCustomers(input: $input) {
      page {
        ${paginationQuery}
      }
      nodes {
        id
        name
        adminId
        adminEmail
        priceMultiplier
        nextPriceMultiplier
        deleted
        hasActiveProduct
        externalPricing
      }
    }
  }
`;

export const getInvoices = gql`
  query getInvoices($input: CustomerInput) {
    getInvoices(input: $input) {
      page {
        ${paginationQuery}
      }
      nodes {
        invoiceId
        price
        cancellation
        customer {
          id
          name
        }
      }
    }
  }
`;

export const getPropertySearcherUsers = gql`
  query getPropertySearcherUsers($filter: FilterOptions) {
    getPropertySearcherUsers(filter: $filter) {
      page {
        ${paginationQuery}
      }
      nodes {
        id
        email
        type
        prospectOptIn
        lastLogin
        searchUntil
        deleted
      }
    }
  }
`;

export const getCustomerById = gql`
  query getCustomerById($id: ID) {
    getCustomerById(id: $id) {
      id
      name
      adminId
      adminEmail
      priceMultiplier
      nextPriceMultiplier
      deleted
      hasActiveProduct
      externalPricing
    }
  }
`;

export const getInvoiceById = gql`
  query getInvoiceById($id: ID) {
    getInvoiceById(id: $id) {
      invoiceId
      price
      cancellation
      customer {
        id
        name
      }
    }
  }
`;

export const cancelInvoice = gql`
  mutation cancelInvoice($id: ID) {
    cancelInvoice(id: $id)
  }
`;

export const deleteCustomerMutation = gql`
  mutation deleteCustomer($id: ID) {
    deleteCustomer(id: $id) {
      status
      statusText
    }
  }
`;

export const enableExternalPricingMutation = gql`
  mutation enableExternalPricing($id: ID, $enable: Boolean) {
    enableExternalPricing(id: $id, enable: $enable) {
      status
      statusText
    }
  }
`;

export interface UserInput extends FilterOptions {
  id?: string;
  name?: string;
  email?: string;
}

export interface CustomerInput extends FilterOptions {
  customerId?: string;
}

export interface ImpersonateUserResponse {
  access_token?: string;
}

export const activatePropertySearcher = gql`
  mutation activatePropertySearcher($email: String!) {
    activatePropertySearcher(email: $email)
  }
`;

export const changeLandlordUserEnabled = gql`
  mutation changeLandlordUserEnabled($id: String!, $enabled: Boolean!) {
    changeLandlordUserEnabled(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`;

export const deletePropertySearcher = gql`
  mutation deletePropertySearcher($id: String!) {
    deletePropertySearcher(id: $id)
  }
`;

export interface ConstantsListResult {
  getAllConstants: {
    [constant: string]: string[];
  };
}

export const constantsList = gql`
  query {
    getAllConstants
  }
`;

export interface PortalCredentialsQueryResult {
  portalCredentials: {
    nodes: PortalCredentials[];
  };
}

export const portalCredentialsQuery = gql`
  query portalCredentials($filter: Filter) {
    portalCredentials(filter: $filter) {
      nodes {
        id
        created
        updated
        portal
        name
        encrypted
        properties
        customer {
          id
        }
      }
    }
  }
`;

export const digitalContractQuotaDiscountsQuery = gql`
  query digitalContractQuotaDiscounts($customerId: String!) {
    digitalContractQuotaDiscounts(customerId: $customerId) {
      quotaPackageId
      value
      endDate
    }
  }
`;

export const digitalContractQuotaPackagesQuery = gql`
  query digitalContractQuotaPackages($customerId: String!) {
    digitalContractQuotaPackages(customerId: $customerId) {
      id
      type
      price
      postDiscountPrice
      quantity
      discount
    }
  }
`;

export const digitalContractQuotaDiscountsMutation = gql`
  mutation saveDigitalContractQuotaDiscounts(
    $customerId: String!
    $discounts: [QuotaDiscountInput]
  ) {
    saveDigitalContractQuotaDiscounts(
      customerId: $customerId
      discounts: $discounts
    ) {
      status
      statusText
    }
  }
`;

export const objectContingentQuotaDiscountsQuery = gql`
  query objectContingentQuotaDiscounts($customerId: String!) {
    objectContingentQuotaDiscounts(customerId: $customerId) {
      quotaPackageId
      value
      endDate
    }
  }
`;

export const objectContingentQuotaPackagesQuery = gql`
  query objectContingentQuotaPackages($customerId: String!) {
    objectContingentQuotaPackages(customerId: $customerId) {
      id
      type
      price
      postDiscountPrice
      quantity
      discount
    }
  }
`;

export const objectContingentQuotaDiscountsMutation = gql`
  mutation saveObjectContingentQuotaDiscounts(
    $customerId: String!
    $discounts: [QuotaDiscountInput]
  ) {
    saveObjectContingentQuotaDiscounts(
      customerId: $customerId
      discounts: $discounts
    ) {
      status
      statusText
    }
  }
`;

export const userImpersonation = gql`
  mutation userImpersonation($email: String!, $userType: String!) {
    userImpersonation(email: $email, userType: $userType) {
      access_token
      session_state
      realm
      endpoint
      client
    }
  }
`;

export const deactivateProductRenew = gql`
  mutation deactivateProductRenew($id: String!, $dueDate: String) {
    deactivateProductRenew(id: $id, dueDate: $dueDate)
  }
`;
