<div class="addon">
  <div class="d-flex justify-content-between">
    <div>
      <h3 class="title-m addon__name">
        {{ addon?.name | translate }}
        @if (dueDate && addon.booked) {
          <app-badge [color]="'light-green'" class="ms-1">
            {{
              addon?.amounts?.expire
                ? 'general.expires_l'
                : ('general.renews_l' | translate)
            }}
            {{ dueDate | appDateTime }}
          </app-badge>
        }
      </h3>
      <p
        class="title-s addon-description"
        [innerHTML]="addon?.description | translate"
      ></p>
    </div>
    <div class="d-flex flex-column align-items-end">
      <h3 class="title-m addon-price" [ngClass]="{ inactive: !isSelected }">
        @if (addon.priceType === addonPriceTypes.MINIMUM) {
          <span>{{ 'admin.addon_price_from_l' | translate }}&nbsp;</span>
        }
        @if (isAdmin) {
          <span class="price">{{ priceNet | currency: currency }}</span>
        } @else {
          <span class="price">{{ priceDiscountNet | currency: currency }}</span>
        }
        <span
          >/{{
            (isYearlySelected ? 'general.year_l' : 'general.month_l')
              | translate
          }}</span
        >
      </h3>
      <div class="addon-checkbox">
        <app-check
          [disabled]="disabled"
          [ngModel]="isSelected"
          [tooltip]="tooltip"
          (ngModelChange)="onChange($event)"
        ></app-check>
      </div>
    </div>
  </div>
  @if (isAdmin && addon) {
    <div class="admin-discount-section">
      <div class="row">
        <div class="col-4 payment-details__section">
          <div class="col-12 pl0">
            <div>
              <div class="d-flex flex-row">
                @if (!isExpired(addonDiscount)) {
                  <div>{{ 'admin.addon_discount_end_l' | translate }}</div>
                }
                @if (isExpired(addonDiscount)) {
                  <div class="font-red">
                    {{ 'admin.addon_discount_end_expired_l' | translate }}
                  </div>
                }
              </div>
              <div class="input-group mb10">
                <app-date
                  [ngClass]="{ expired: isExpired(addonDiscount) }"
                  [(ngModel)]="addonDiscount.endDate"
                  [minDate]="minDate"
                  [disabled]="!isSelected"
                  (ngModelChange)="onDiscountDateChange($event, addonDiscount)"
                ></app-date>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 payment-details__section">
          <div class="col-12 pl0">
            <div>
              <div class="d-flex flex-row">
                <div>{{ 'admin.addon_discount_l' | translate }}</div>
              </div>
              <div class="input-group mb10">
                <input
                  appInput
                  type="number"
                  [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                  [min]="ADDON_DISCOUNT_CONFIG.minValue"
                  [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                  class="form-control"
                  [(ngModel)]="addonDiscount.value"
                  [required]="!!addonDiscount?.endDate"
                  [readonly]="!isSelected || !addonDiscount?.endDate"
                  (ngModelChange)="onDiscountValueChange($event, addonDiscount)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col payment-details__section">
          <div class="col-12 pl0">
            <div class="d-flex flex-row">
              <div>{{ 'admin.addon_discount_final_price_l' | translate }}</div>
            </div>
            <div>
              @if (!recalculatingPrice) {
                <span
                  >{{ priceDiscountNet | currency: currency }}/{{
                    (isYearlySelected ? 'general.year_l' : 'general.month_l')
                      | translate
                  }}</span
                >
              }
              @if (recalculatingPrice) {
                <span>...</span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if (this.addon?.price?.setup && !recalculatingPrice && setupPrice) {
    <span class="one-time-fee"
      >{{ 'subscription.addon_onetime_setup_fee' | translate }}&nbsp;{{
        setupPrice | currency: currency
      }}</span
    >
  }
  <div class="extra-content">
    <ng-content></ng-content>
  </div>
</div>
