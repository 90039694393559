import { gql } from 'apollo-angular';
import { Prioset } from '@ui/shared/models';

export interface PriosetsUpdateResult {
  updatePrioset?: Prioset;
}

export interface UpdatePriosetInput {
  data: { [key: string]: any };
  description?: string;
  locked?: boolean;
  name: string;
  priosetID: string;
  template?: boolean;
}

export const updatePriosetMutation = gql`
  mutation updatePrioset($input: UpdatePriosetInput!) {
    updatePrioset(input: $input) {
      id
      data
      description
      locked
      name
      template
      updated
      sizeOfProperties
    }
  }
`;
