import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject
} from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormsModule
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  Addon,
  AddonDiscount,
  AddonType,
  PriceTypes,
  QuotaDiscount,
  QuotaPackage
} from '@ui/shared/models';
import { ADDON_DISCOUNT_CONFIG } from 'libs/products/products-module.config';

import { AddonsHelperService } from 'libs/products/services';
import { stripTypenameProperty } from 'libs/utils';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { ComponentsModule } from 'libs/components';
import { AddonComponent } from '../addon/addon.component';
import { DateTimePipe } from '../../../pipes/date-time.pipe';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubscriptionDetailsComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    ComponentsModule,
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    AddonComponent,
    CurrencyPipe,
    TranslateModule,
    DateTimePipe
  ]
})
export class SubscriptionDetailsComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  private addonsHelperService = inject(AddonsHelperService);

  @Input() addons: Addon[];
  @Input() customerAddons: Addon[] = [];
  @Input() currency: string;
  @Input() recalculatingPrice = false;
  @Input() dueDate: string;
  @Input() disabled = false;
  @Input() isYearlySelected = false;
  @Input() isAdmin: boolean;
  @Input() addonDiscounts: AddonDiscount[];
  @Input() digitalContractQuotaPackages: QuotaPackage[];
  @Input() objectContingentQuotaPackages: QuotaPackage[];

  @Input()
  get digitalContractQuotaDiscounts(): QuotaDiscount[] {
    return this.digitalContractQuotaDiscountsArray;
  }

  set digitalContractQuotaDiscounts(value: QuotaDiscount[]) {
    this.digitalContractQuotaDiscountsArray = [...value];
  }

  @Input()
  get objectContingentQuotaDiscounts(): QuotaDiscount[] {
    return this.objectContingentQuotaDiscountsArray;
  }

  set objectContingentQuotaDiscounts(value: QuotaDiscount[]) {
    this.objectContingentQuotaDiscountsArray = [...value];
  }

  @Output() discountChange = new EventEmitter<AddonDiscount>();
  @Output() saveQuotaDiscounts = new EventEmitter<QuotaDiscount[]>();
  @Output() saveObjectContingentQuotaDiscounts = new EventEmitter<
    QuotaDiscount[]
  >();

  public readonly ADDON_DISCOUNT_CONFIG = ADDON_DISCOUNT_CONFIG;

  public digitalContractQuotaDiscountsArray: QuotaDiscount[] = [];
  public objectContingentQuotaDiscountsArray: QuotaDiscount[] = [];
  public dmvQuotaDiscounts: QuotaDiscount[] = [];
  public oCQuotaDiscounts: QuotaDiscount[] = [];
  public minDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public totalAgentsCount = 1;
  public totalFreeAgentsCount = 0;
  public totalHomepageModuleCount = 0;
  public totalCustomerCooperationsCount = 0;
  public featureAddons: Addon[] = [];
  public value: Addon[] = [];

  public additionalAgentsModule = 0;
  public additionalHomepageModules = 0;
  public additionalCustomerCooperations = 0;

  /* eslint-disable @typescript-eslint/ban-types */
  private onChange: Function;
  private onTouch: Function;

  /* eslint-enable @typescript-eslint/ban-types */

  get agentAddon(): Addon {
    return this.addonsHelperService.extractAgent(this.value || []);
  }

  get freeAgentAddon(): Addon {
    return this.addonsHelperService.extractFreeAgent(this.value || []);
  }

  get homepageModuleAddon(): Addon {
    return this.addonsHelperService.extractHomepageModule(this.value || []);
  }

  get customerCooperationAddon(): Addon {
    return this.addonsHelperService.extractCustomerCooperation(
      this.value || []
    );
  }

  get agentDiscount() {
    return this.addonsHelperService.extractAgentDiscount(this.value || []);
  }

  get freeAgentDiscount() {
    return this.addonsHelperService.extractFreeAgentDiscount(this.value || []);
  }

  get homepageModuleDiscount() {
    return this.addonsHelperService.extractHomepageModuleDiscount(
      this.value || []
    );
  }

  get customerCooperationDiscount() {
    return this.addonsHelperService.extractCustomerCooperationDiscount(
      this.value || []
    );
  }

  get agentsAmount() {
    const agentsAmount = this.addonsHelperService.extractAgent(
      this.value
    )?.amounts;
    return agentsAmount?.renew + agentsAmount?.expire || 1;
  }

  get freeAgentsAmount() {
    const agentsAmount = this.addonsHelperService.extractFreeAgent(
      this.value
    )?.amounts;
    return agentsAmount?.renew + agentsAmount?.expire;
  }

  get homepageModuleAmount() {
    const homepageModuleAmount = this.addonsHelperService.extractHomepageModule(
      this.value
    )?.amounts;
    return homepageModuleAmount?.renew + homepageModuleAmount?.expire || 0;
  }

  get customerCooperationsAmount() {
    const customerCooperationsAmount =
      this.addonsHelperService.extractCustomerCooperation(this.value)?.amounts;
    return (
      customerCooperationsAmount?.renew + customerCooperationsAmount?.expire ||
      0
    );
  }

  get renewedAgents() {
    return (
      this.value?.find(a => a?.type === AddonType.AGENT)?.amounts?.renew - 1 ||
      0
    );
  }

  get renewedFreeAgents() {
    return (
      this.value?.find(a => a?.type === AddonType.FREE_AGENT)?.amounts?.renew ||
      0
    );
  }

  get renewedHomepageModules(): number {
    return (
      this.value?.find(a => a?.type === AddonType.HOMEPAGE_MODULE_REST)?.amounts
        ?.renew || 0
    );
  }

  get renewedCustomerCooperations(): number {
    return (
      this.value?.find(a => a?.type === AddonType.CUSTOMER_COOPERATION)?.amounts
        ?.renew || 0
    );
  }

  get expireAgents() {
    return (
      this.value?.find(a => a?.type === AddonType.AGENT)?.amounts?.expire || 0
    );
  }

  get expireFreeAgents() {
    return (
      this.value?.find(a => a?.type === AddonType.FREE_AGENT)?.amounts
        ?.expire || 0
    );
  }

  get expireHomepageModules() {
    return (
      this.value?.find(a => a?.type === AddonType.HOMEPAGE_MODULE_REST)?.amounts
        ?.expire || 0
    );
  }

  get expireCustomerCooperations() {
    return (
      this.value?.find(a => a?.type === AddonType.CUSTOMER_COOPERATION)?.amounts
        ?.expire || 0
    );
  }

  public ngOnInit(): void {
    const addons = this.addons.map(a => ({
      ...a,
      ...this.customerAddons?.find(va => va.type === a.type)
    }));
    this.featureAddons = this.addonsHelperService.extractSupportedFeatureAddons(
      addons || []
    );
    this.dmvQuotaDiscounts = [...this.digitalContractQuotaDiscounts];
    this.oCQuotaDiscounts = [...this.objectContingentQuotaDiscounts];
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.customerAddons?.currentValue) {
      this.addons = this.addons?.map(
        (a): Addon =>
          ({
            ...a,
            ...(changes?.customerAddons?.currentValue as Addon[])?.find(
              va => va.id === a.id
            )
          }) as Addon
      );
    }
    this.featureAddons = this.addonsHelperService.extractSupportedFeatureAddons(
      this.addons || []
    );
  }

  public isAddonVisible(addon: Addon): boolean {
    const isHPModuleVisible = !(
      addon.type === AddonType.HPMODULE &&
      !this.isAdmin &&
      addon.amounts?.expire + addon.amounts?.renew === 0
    );
    return isHPModuleVisible;
  }

  public isDigitalContractBooked(addon: Addon) {
    return (
      addon.type === AddonType.DIGITAL_CONTRACT &&
      (this.isBooked(addon) || this.isAddonSelected(addon))
    );
  }

  public isExpired(discount: AddonDiscount | QuotaDiscount) {
    return (
      discount.value !== 0 &&
      new Date(discount.endDate).getTime() < new Date().getTime()
    );
  }

  public getAgentsPrice(includeDiscount = false): number {
    return this.addonsHelperService.getAgentsPrice(
      this.value,
      PriceTypes.NET,
      includeDiscount,
      this.isYearlySelected
    );
  }

  public getHomepageModulePrice(includeDiscount = false): number {
    return this.addonsHelperService.getHomepageModulePrice(
      this.value,
      PriceTypes.NET,
      includeDiscount,
      this.isYearlySelected
    );
  }

  public getCustomerCooperationPrice(includeDiscount = false): number {
    return this.addonsHelperService.getCustomerCooperationPrice(
      this.value,
      PriceTypes.NET,
      includeDiscount,
      this.isYearlySelected
    );
  }

  public getAddonDiscount(addon: Addon) {
    return { ...this.addonDiscounts.find(ad => ad.addonId === addon.id) };
  }

  public getAddonPrice(addon: Addon) {
    return (
      addon.price || this.customerAddons?.find(ad => ad.id === addon.id)?.price
    );
  }

  public isBooked(addon: Addon) {
    return this.customerAddons?.find(ad => ad.id === addon.id)?.booked;
  }

  public isInCurrentProduct(addon: Addon) {
    return (
      this.customerAddons?.some(curAddon => curAddon?.id === addon.id) || false
    );
  }

  public isSelected(addon: Addon) {
    return this.value.find(a => a?.id === addon.id)?.amounts?.renew > 0;
  }

  public isAddonSelected(addon: Addon) {
    return this.value.some(a => a?.id === addon.id && a?.amounts?.renew);
  }

  public onToggleAddon(addon: Addon) {
    if (this.isAddonSelected(addon)) {
      this.value = this.value.filter(a => a?.id !== addon.id);
    } else {
      const newValue = this.value.filter(a => a?.id !== addon.id);
      this.value = [
        ...newValue,
        { ...addon, amounts: { renew: 1, expire: 0 }, booked: true }
      ];
    }
    this.onChange(this.value);
    this.onTouch();
  }

  public onAddAgent() {
    if (this.disabled) return;
    this.value = this.value.map(addon => {
      let renew = addon?.amounts?.renew;
      let expire = addon?.amounts?.expire;
      if (addon?.amounts?.renew && addon?.type === AddonType.AGENT) {
        renew = addon.amounts.renew + 1;
        expire = addon.amounts.expire
          ? addon.amounts.expire - 1
          : addon.amounts.expire;
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.additionalAgentsModule++;
    this.onChange(this.value);
    this.onTouch();
  }

  public onAddFreeAgent() {
    if (this.disabled) return;
    this.value = this.value.map(addon => {
      if (addon?.type === AddonType.FREE_AGENT) {
        const renew = addon.amounts.renew + 1;

        return { ...addon, amounts: { ...addon.amounts, renew } };
      }
      return addon;
    });

    this.onChange(this.value);
    this.onTouch();
  }

  public onAddHomepageModule() {
    if (this.disabled) return;
    this.value = this.value.map(addon => {
      let renew = addon.amounts.renew;
      let expire = addon.amounts.expire;
      if (addon?.type === AddonType.HOMEPAGE_MODULE_REST) {
        renew++;
        expire = expire > 0 ? expire - 1 : 0;
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.additionalHomepageModules++;
    this.onChange(this.value);
    this.onTouch();
  }

  public onAddCustomerCooperation() {
    if (this.disabled) return;
    this.value = this.value.map(addon => {
      let renew = addon.amounts.renew;
      let expire = addon.amounts.expire;
      if (addon?.type === AddonType.CUSTOMER_COOPERATION) {
        renew++;
        expire = expire > 0 ? expire - 1 : 0;
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.additionalCustomerCooperations++;
    this.onChange(this.value);
    this.onTouch();
  }

  public onRemoveAgent() {
    if (
      this.disabled ||
      this.agentsAmount === 1 ||
      (this.additionalAgentsModule === 0 && !this.isAdmin)
    ) {
      return;
    }

    if (this.additionalAgentsModule > 0) {
      this.additionalAgentsModule--;
    }

    this.value = this.value.map(addon => {
      let renew = addon.amounts.renew;
      let expire = addon.amounts.expire;
      if (addon.type === AddonType.AGENT) {
        if (renew > 1) {
          renew--;
          if (
            this.totalAgentsCount - 1 > expire &&
            renew < this.totalAgentsCount &&
            this.agentAddon.booked
          ) {
            expire++;
          }
        }
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.onChange(this.value);
    this.onTouch();
  }

  public onRemoveFreeAgent() {
    if (
      this.disabled ||
      this.freeAgentsAmount === 0 ||
      this.renewedFreeAgents === 0
    ) {
      return;
    }

    this.value = this.value.map(addon => {
      if (addon.type === AddonType.FREE_AGENT) {
        let renew = addon.amounts.renew;
        let expire = addon.amounts.expire;

        if (renew >= 0) {
          renew--;
          if (
            this.totalFreeAgentsCount > expire &&
            renew < this.totalFreeAgentsCount
          ) {
            expire++;
          }
        }
        return { ...addon, amounts: { renew, expire } };
      }
      return addon;
    });
    this.onChange(this.value);
    this.onTouch();
  }

  public onRemoveHomepageModule() {
    if (
      this.disabled ||
      this.homepageModuleAmount < 1 ||
      (this.additionalHomepageModules === 0 && !this.isAdmin)
    ) {
      return;
    }

    if (this.additionalHomepageModules > 0) {
      this.additionalHomepageModules--;
    }

    this.value = this.value.map(addon => {
      let renew = addon.amounts.renew;
      let expire = addon.amounts.expire;
      if (addon.type === AddonType.HOMEPAGE_MODULE_REST) {
        if (renew > 0) {
          renew--;
          if (
            this.totalHomepageModuleCount > expire &&
            renew < this.totalHomepageModuleCount &&
            this.homepageModuleAddon.booked
          ) {
            expire++;
          }
        }
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.onChange(this.value);
    this.onTouch();
  }

  public onRemoveCustomerCooperation() {
    if (
      this.disabled ||
      this.customerCooperationsAmount < 1 ||
      (this.additionalCustomerCooperations === 0 && !this.isAdmin)
    ) {
      return;
    }

    if (this.additionalCustomerCooperations > 0) {
      this.additionalCustomerCooperations--;
    }

    this.value = this.value.map(addon => {
      let renew = addon.amounts.renew;
      let expire = addon.amounts.expire;
      if (addon.type === AddonType.CUSTOMER_COOPERATION) {
        if (renew > 0) {
          renew--;
          if (
            this.totalCustomerCooperationsCount > expire &&
            renew < this.totalCustomerCooperationsCount &&
            this.customerCooperationAddon.booked
          ) {
            expire++;
          }
        }
      }
      return { ...addon, amounts: { renew, expire } };
    });
    this.onChange(this.value);
    this.onTouch();
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public onDiscountChange(discount: AddonDiscount) {
    if (
      discount.value < ADDON_DISCOUNT_CONFIG.minValue &&
      discount.value > ADDON_DISCOUNT_CONFIG.maxValue
    ) {
      return;
    }

    this.value = this.value.map(addon => {
      if (addon?.id === discount.addonId) {
        addon.discount = { ...discount };
      }
      return addon;
    });

    this.addonDiscounts = this.addonDiscounts.map(discountA =>
      discountA.addonId === discount.addonId ? discount : discountA
    );

    this.onChange(this.value);
    this.onTouch();
    this.discountChange.emit(discount);
  }

  public onDiscountValueChange(value, addonDiscount: AddonDiscount) {
    this.onDiscountChange({
      ...addonDiscount,
      value
    });
  }

  public onDiscountDateChange(endDate, addonDiscount: AddonDiscount) {
    this.onDiscountChange({
      ...addonDiscount,
      endDate
    });
  }

  public writeValue(value: Addon[]) {
    this.value = value;
    this.totalHomepageModuleCount =
      this.renewedHomepageModules + this.expireHomepageModules;
    this.totalCustomerCooperationsCount =
      this.renewedCustomerCooperations + this.expireCustomerCooperations;
    this.totalAgentsCount = this.renewedAgents + this.expireAgents + 1;
    this.totalFreeAgentsCount = this.renewedFreeAgents + this.expireFreeAgents;
    if (!this.agentAddon?.amounts) {
      this.value = this.value?.map(addon => {
        if (addon.type === AddonType.AGENT) {
          return {
            ...addon,
            amounts: {
              renew: 1,
              expire: 0
            }
          };
        }
        return addon;
      });
    }
    if (!this.freeAgentAddon) {
      this.value = [
        ...(this.value || []),
        this.addonsHelperService.getFreeAgentDefault(this.isYearlySelected)
      ];
    }
  }

  public getQuotaDiscount(quotaPackageId: string) {
    const index = this.dmvQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      const dmvQuotaDiscount = {
        ...this.dmvQuotaDiscounts[index]
      };
      return dmvQuotaDiscount;
    } else {
      const tmp = {
        quotaPackageId,
        value: 0,
        endDate: moment(new Date())
          .year(moment(new Date()).year() + 1)
          .format('YYYY-MM-DD')
      };
      this.digitalContractQuotaDiscounts = [
        ...this.digitalContractQuotaDiscounts,
        tmp
      ];
      return tmp;
    }
  }

  public onSaveQuotaDiscounts() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const tmp = this.dmvQuotaDiscounts.map(item => stripTypenameProperty(item));
    this.saveQuotaDiscounts.emit(tmp);
  }

  public getQuotaDiscountPrice(quotaPackage: QuotaPackage) {
    const discount = this.getQuotaDiscount(quotaPackage.id);
    const value = quotaPackage.price * discount.value;
    return quotaPackage.price - value;
  }

  public onChangeValueDmv(quotaPackageId: string, value) {
    const index = this.dmvQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      this.dmvQuotaDiscounts[index] = {
        ...this.dmvQuotaDiscounts[index],
        value
      };
    } else {
      const quotaPackage = {
        quotaPackageId,
        value: value,
        endDate: moment(new Date())
          .year(moment(new Date()).year() + 1)
          .format('YYYY-MM-DD')
      };
      this.dmvQuotaDiscounts = [...this.dmvQuotaDiscounts, quotaPackage];
    }
  }

  public changeDateDmv(quotaPackageId: string, endDate) {
    const index = this.dmvQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      this.dmvQuotaDiscounts[index] = {
        ...this.dmvQuotaDiscounts[index],
        endDate
      };
    } else {
      const quotaPackage = {
        quotaPackageId,
        value: 0,
        endDate: endDate
      };
      this.dmvQuotaDiscounts = [...this.dmvQuotaDiscounts, quotaPackage];
    }
  }

  public getObjectContingentQuotaDiscount(quotaPackageId: string) {
    const index = this.oCQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      const objectContingentQuotaDiscount = {
        ...this.oCQuotaDiscounts[index]
      };

      return objectContingentQuotaDiscount;
    } else {
      const tmp = {
        quotaPackageId,
        value: 0,
        endDate: moment(new Date())
          .year(moment(new Date()).year() + 1)
          .format('YYYY-MM-DD')
      };
      this.objectContingentQuotaDiscounts = [
        ...this.objectContingentQuotaDiscounts,
        tmp
      ];
      return tmp;
    }
  }

  public onSaveObjectContingentQuotaDiscounts() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const tmp = this.oCQuotaDiscounts.map(item => stripTypenameProperty(item));
    this.saveObjectContingentQuotaDiscounts.emit(tmp);
  }

  public getObjectContingentQuotaDiscountPrice(quotaPackage: QuotaPackage) {
    const discount = this.getObjectContingentQuotaDiscount(quotaPackage.id);
    const value = quotaPackage.price * discount.value;
    return quotaPackage.price - value;
  }

  public onChangeValue(quotaPackageId: string, value) {
    const index = this.oCQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      this.oCQuotaDiscounts[index] = {
        ...this.oCQuotaDiscounts[index],
        value
      };
    } else {
      const quotaPackage = {
        quotaPackageId,
        value: value,
        endDate: moment(new Date())
          .year(moment(new Date()).year() + 1)
          .format('YYYY-MM-DD')
      };
      this.oCQuotaDiscounts = [...this.oCQuotaDiscounts, quotaPackage];
    }
  }

  public changeDate(quotaPackageId: string, endDate) {
    const index = this.oCQuotaDiscounts.findIndex(
      discount => discount.quotaPackageId === quotaPackageId
    );
    if (index > -1) {
      this.oCQuotaDiscounts[index] = {
        ...this.oCQuotaDiscounts[index],
        endDate
      };
    } else {
      const quotaPackage = {
        quotaPackageId,
        value: 0,
        endDate: endDate
      };
      this.oCQuotaDiscounts = [...this.oCQuotaDiscounts, quotaPackage];
    }
  }
}
