import { Component, inject } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LandlordCustomerSize, PaymentMethodType } from '@ui/shared/models';

import { Addon, SubscriptionPeriod, PriceTypes } from '@ui/shared/models';

import { AddonsHelperService } from 'libs/products/services';
import { SubscriptionsSummary } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { ComponentsModule } from 'libs/components';
import { DateTimePipe } from '../../../pipes/date-time.pipe';

@Component({
  selector: 'app-update-subscription-modal',
  templateUrl: './update-subscription-modal.component.html',
  styleUrls: ['./update-subscription-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, CurrencyPipe, TranslateModule, DateTimePipe]
})
export class UpdateSubscriptionModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);
  private addonsHelperService = inject(AddonsHelperService);

  public summary: SubscriptionsSummary;
  public currency: string;

  public readonly periodsMap = {
    [SubscriptionPeriod.MONTHLY]: 'general.monthly_l',
    [SubscriptionPeriod.YEARLY]: 'general.yearly_l'
  };

  public readonly sizesMap = {
    [LandlordCustomerSize.PRIVATE]: '<10',
    [LandlordCustomerSize.SMALL]: '<2000',
    [LandlordCustomerSize.MEDIUM]: '<4000',
    [LandlordCustomerSize.LARGE]: '≤6000',
    [LandlordCustomerSize.ENTERPRISE]: '>6000'
  };

  public yearlyDiscount = {
    value: '17%'
  };

  public get agentsAmount() {
    return this.addonsHelperService.extractAgent(this.summary.addons)?.amounts
      ?.renew;
  }

  public get agentsPrice() {
    return this.addonsHelperService.getAgentsPrice(
      this.summary.addons,
      PriceTypes.NET,
      true,
      this.isYearlySelected
    );
  }

  public get freeAgentsAmount() {
    return this.addonsHelperService.extractFreeAgent(this.summary.addons)
      ?.amounts?.renew;
  }

  public get homepageAmount() {
    return this.addonsHelperService.extractHomepageModule(this.summary.addons)
      ?.amounts?.renew;
  }

  public get homepagePrice() {
    return this.addonsHelperService.getHomepageModulePrice(
      this.summary.addons,
      PriceTypes.NET,
      true,
      this.isYearlySelected
    );
  }

  public get customerCooperationAmount() {
    return this.addonsHelperService.extractCustomerCooperation(
      this.summary.addons
    )?.amounts?.renew;
  }

  public get customerCooperationPrice() {
    return this.addonsHelperService.getCustomerCooperationPrice(
      this.summary.addons,
      PriceTypes.NET,
      true,
      this.isYearlySelected
    );
  }

  public get featureAddons() {
    return this.addonsHelperService
      .extractSupportedFeatureAddons(this.summary.addons)
      .filter(a => a.amounts?.renew);
  }

  public get isPaymentInvoice() {
    return (
      this.summary.paymentMethod &&
      this.summary.paymentMethod.type === PaymentMethodType.INVOICE
    );
  }

  public get isYearlySelected() {
    return (
      this.summary.product.subscriptionPeriod === SubscriptionPeriod.YEARLY
    );
  }

  public getAddonPriceMonthly(addon: Addon) {
    return addon.price.postDiscountMonthlyPriceNet;
  }

  public getAddonPriceYearly(addon: Addon) {
    return addon.price.postDiscountYearlyPriceNet;
  }

  public update() {
    this.ngbActiveModal.close();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
