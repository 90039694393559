import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo } from 'apollo-angular';
import {
  Addon,
  AddonPrices,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage
} from '@ui/shared/models';
import { stripTypenameProperty } from 'libs/utils';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';

import { getResponseValidator, LocalStorageService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

import {
  createSubscriptionMutation,
  getLandlordProductQuery,
  getProductsQuery,
  LandlordProductsQueryResponse,
  recalculateAddonPriceQuery,
  recalculateAddonsPriceQuery,
  updateNextProductMutation,
  updateSubscriptionMutation
} from 'admin/+state/products/products.query';
import {
  notificationConfig,
  notificationConfig as notification
} from 'admin/config';
import * as fromLandlordSelector from 'admin/+state/landlord/landlord.selectors';
import * as fromActions from 'admin/+state/landlord/landlord.actions';
import * as fromEditLandlordSelector from 'admin/screens/landlord/+state/landlords/landlords.selectors';
import moment from 'moment';

import {
  deactivateProductRenew,
  digitalContractQuotaDiscountsMutation,
  digitalContractQuotaDiscountsQuery,
  digitalContractQuotaPackagesQuery,
  objectContingentQuotaDiscountsMutation,
  objectContingentQuotaDiscountsQuery,
  objectContingentQuotaPackagesQuery,
  UsersListResult
} from '../queries';
import * as productActions from './products.actions';

import { ProductsState } from './products.reducers';

@Injectable()
export class ProductsEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<ProductsState>>(Store);
  private apollo = inject(Apollo);
  private storageService = inject(LocalStorageService);

  loadCustomerProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadCustomerProduct>(
        productActions.LOAD_CUSTOMER_PRODUCT
      ),
      switchMap(({ customerID }) =>
        this.apollo
          .query<LandlordProductsQueryResponse>({
            query: getLandlordProductQuery,
            variables: { customerID },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(result => result.data.getLandlordProduct),
            mergeMap(currentProduct => [
              new productActions.LoadCustomerProductSuccess(
                currentProduct || {},
                customerID
              )
            ]),
            catchError(err => [
              new productActions.LoadCustomerProductFail(err),
              new fromBaseState.ShowError(err.message)
            ])
          )
      )
    )
  );

  loadAvailableProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadAvailableProducts>(
        productActions.LOAD_AVAILABLE_PRODUCTS
      ),
      map((action: productActions.LoadAvailableProducts) => action),
      switchMap(({ multiplier }) => {
        const location = this.storageService
          .getItem<string>('locale')
          .toUpperCase();
        return this.apollo
          .query<{ getProducts }>({
            query: getProductsQuery,
            variables: { input: { multiplier, location } },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(result => result.data.getProducts as ProductOverview[]),
            map(
              availableProducts =>
                new productActions.LoadAvailableProductsSuccess(
                  availableProducts
                )
            ),
            catchError(err =>
              of(new productActions.LoadAvailableProductsFail(err))
            )
          );
      })
    )
  );

  recalculateAddonPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.RecalculateAddonPrice>(
        productActions.RECALCULATE_ADDON_PRICE
      ),
      withLatestFrom(this.store.select(fromEditLandlordSelector.getLandlord)),
      switchMap(
        ([
          {
            data: {
              addonId,
              addonDiscount,
              addonDiscountEndDate,
              priceMultiplier,
              nextPriceMultiplier,
              discount,
              discountEndDate
            }
          },
          userData
        ]) => {
          const location = this.storageService
            .getItem<string>('locale')
            .toUpperCase();
          return this.apollo
            .query<{ recalculateAddonPrice: AddonPrices }>({
              query: recalculateAddonPriceQuery,
              variables: {
                input: {
                  addonId,
                  addonDiscount,
                  addonDiscountEndDate,
                  location,
                  priceMultiplier,
                  nextPriceMultiplier,
                  discount,
                  discountEndDate,
                  customerId: userData?.customer?.id
                }
              },
              fetchPolicy: 'no-cache'
            })
            .pipe(
              map(
                result =>
                  new productActions.RecalculateAddonPriceSuccess(
                    addonId,
                    result.data.recalculateAddonPrice,
                    userData?.customer?.id || 'new'
                  )
              ),
              catchError(err =>
                of(new productActions.RecalculateAddonPriceFail(err))
              )
            );
        }
      )
    )
  );

  recalculateAddonsPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.RecalculateAddonsPrice>(
        productActions.RECALCULATE_ADDONS_PRICE
      ),
      withLatestFrom(this.store.select(fromEditLandlordSelector.getLandlord)),
      switchMap(
        ([
          {
            data: {
              addonDiscounts,
              discount,
              discountEndDate,
              priceMultiplier,
              nextPriceMultiplier
            }
          },
          userData
        ]) => {
          const customerLocation = this.storageService
            .getItem<string>('locale')
            .toUpperCase();
          return this.apollo
            .query<{ recalculateAddonsPrice: Addon[] }>({
              query: recalculateAddonsPriceQuery,
              variables: {
                input: {
                  addonDiscounts: addonDiscounts.map(a =>
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    stripTypenameProperty(a)
                  ),
                  customerDiscount: discount,
                  customerDiscountEndDate: discountEndDate,
                  multiplier: priceMultiplier,
                  nextMultiplier: nextPriceMultiplier,
                  customerLocation,
                  customerId: userData?.customer?.id
                }
              },
              fetchPolicy: 'no-cache'
            })
            .pipe(
              tap(() => getResponseValidator()),
              map(
                result =>
                  new productActions.RecalculateAddonsPriceSuccess(
                    result.data.recalculateAddonsPrice,
                    userData?.customer?.id || 'new'
                  )
              ),
              catchError(err =>
                of(new productActions.RecalculateAddonsPriceFail(err))
              )
            );
        }
      )
    )
  );

  loadDigitalContractQuotaDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadDigitalContractQuotaDiscount>(
        productActions.LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT
      ),
      switchMap(({ customerId }) => {
        return this.apollo
          .query<{ digitalContractQuotaDiscounts }>({
            query: digitalContractQuotaDiscountsQuery,
            variables: { customerId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                response.data.digitalContractQuotaDiscounts as QuotaDiscount[]
            ),
            map(
              discounts =>
                new productActions.LoadDigitalContractQuotaDiscountSuccess(
                  discounts
                )
            ),
            catchError(err =>
              of(new productActions.LoadDigitalContractQuotaDiscountFail(err))
            )
          );
      })
    )
  );

  loadDigitalContractQuotaPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadDigitalContractQuotaPackages>(
        productActions.LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT
      ),
      switchMap(({ customerId }) => {
        return this.apollo
          .query<{ digitalContractQuotaPackages }>({
            query: digitalContractQuotaPackagesQuery,
            variables: { customerId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                response.data.digitalContractQuotaPackages as QuotaPackage[]
            ),
            map(
              packages =>
                new productActions.LoadDigitalContractQuotaPackagesSuccess(
                  packages
                )
            ),
            catchError(err =>
              of(new productActions.LoadDigitalContractQuotaPackagesFail(err))
            )
          );
      })
    )
  );

  saveDigitalContractQuotaDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.SaveDigitalContractQuotaDiscount>(
        productActions.SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT
      ),
      switchMap(({ customerId, discounts }) => {
        return this.apollo
          .mutate({
            mutation: digitalContractQuotaDiscountsMutation,
            variables: { customerId, discounts },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            mergeMap(() => [
              new productActions.LoadDigitalContractQuotaDiscount(customerId),
              new productActions.SaveDigitalContractQuotaDiscountSuccess(),
              new fromBaseState.ShowInfo(
                notification.digital_contract.save.success
              )
            ]),
            catchError(err => [
              new productActions.SaveDigitalContractQuotaDiscountFail(err),
              new fromBaseState.ShowError(
                notification.digital_contract.save.error
              )
            ])
          );
      })
    )
  );

  loadObjectContingentQuotaDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadObjectContingentQuotaDiscount>(
        productActions.LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT
      ),
      switchMap(({ customerId }) => {
        return this.apollo
          .query<{ objectContingentQuotaDiscounts }>({
            query: objectContingentQuotaDiscountsQuery,
            variables: { customerId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                response.data.objectContingentQuotaDiscounts as QuotaDiscount[]
            ),
            map(
              discounts =>
                new productActions.LoadObjectContingentQuotaDiscountSuccess(
                  discounts
                )
            ),
            catchError(err =>
              of(new productActions.LoadObjectContingentQuotaDiscountFail(err))
            )
          );
      })
    )
  );

  loadObjectContingentQuotaPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.LoadObjectContingentQuotaPackages>(
        productActions.LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES
      ),
      switchMap(({ customerId }) => {
        return this.apollo
          .query<{ objectContingentQuotaPackages }>({
            query: objectContingentQuotaPackagesQuery,
            variables: { customerId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                response.data.objectContingentQuotaPackages as QuotaPackage[]
            ),
            map(
              packages =>
                new productActions.LoadObjectContingentQuotaPackagesSuccess(
                  packages
                )
            ),
            catchError(err =>
              of(new productActions.LoadObjectContingentQuotaPackagesFail(err))
            )
          );
      })
    )
  );

  saveObjectContingentQuotaDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.SaveObjectContingentQuotaDiscount>(
        productActions.SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT
      ),
      switchMap(({ customerId, discounts }) => {
        return this.apollo
          .mutate({
            mutation: objectContingentQuotaDiscountsMutation,
            variables: { customerId, discounts },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            mergeMap(() => [
              new productActions.LoadObjectContingentQuotaDiscount(customerId),
              new productActions.LoadObjectContingentQuotaPackages(customerId),
              new productActions.SaveObjectContingentQuotaDiscountSuccess(),
              new fromBaseState.ShowInfo(
                notification.object_contingent.save.success
              )
            ]),
            catchError(err => [
              new productActions.SaveObjectContingentQuotaDiscountFail(err),
              new fromBaseState.ShowError(
                notification.object_contingent.save.error
              )
            ])
          );
      })
    )
  );

  deactivateProductRenew$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.DeactivateProductRenew>(
        productActions.DEACTIVATE_PRODUCT_RENEW
      ),
      switchMap(({ id, dueDate }) => {
        return this.apollo
          .mutate<UsersListResult>({
            mutation: deactivateProductRenew,
            variables: { id, dueDate: moment(dueDate).format('DD-MM-YYYY') }
          })
          .pipe(
            tap(getResponseValidator<UsersListResult>()),
            map(response => response.data.deactivateProductRenew),
            withLatestFrom(
              this.store.select(fromLandlordSelector.getCustomersData)
            ),
            mergeMap(([res, data]) => {
              const actions = [];
              actions.push(new productActions.DeactivateProductRenewSuccess());
              if (res.message) {
                actions.push(new fromBaseState.ShowError(res.message));
              } else {
                actions.push(
                  new fromBaseState.ShowInfo(
                    notification.customer.disable.success
                  )
                );
              }
              data.length === 1
                ? actions.push(new fromActions.FetchCustomerById(id))
                : actions.push(new fromActions.FetchCustomers({}));
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return actions;
            }),
            catchError(err => [
              new productActions.LoadAvailableProductsFail(err),
              new fromBaseState.ShowError(notification.customer.disable.error)
            ])
          );
      })
    )
  );

  updateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.UPDATE_SUBSCRIPTION),
      switchMap((action: productActions.UpdateSubscription) => {
        return this.apollo
          .mutate<{ updateSubscription: ProductOverview }>({
            mutation: updateSubscriptionMutation,
            variables: {
              payload: action.payload
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(result => result.data.updateSubscription),
            mergeMap(updateProduct => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [
                new fromBaseState.ShowInfo(
                  notificationConfig.subscriptions.update.success
                ),
                new productActions.UpdateSubscriptionSuccess(
                  updateProduct,
                  action.payload.customerID
                )
              ];
            }),
            catchError(err => of(new fromBaseState.ShowError(err)))
          );
      })
    )
  );

  createSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.CreateSubscription>(
        productActions.CREATE_SUBSCRIPTION
      ),
      switchMap(action => {
        return this.apollo
          .mutate<{ createSubscription }>({
            mutation: createSubscriptionMutation,
            variables: {
              payload: action.payload
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(result => result.data.createSubscription as ProductOverview),
            mergeMap(createdProduct => [
              new fromBaseState.ShowInfo(
                notificationConfig.subscriptions.update.success
              ),
              new productActions.CreateSubscriptionSuccess(
                createdProduct,
                action.payload.customerId
              )
            ]),
            catchError(err => of(new fromBaseState.ShowError(err)))
          );
      })
    )
  );

  updateNextProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType<productActions.UpdateNextProduct>(
        productActions.UPDATE_NEXT_PRODUCT
      ),
      switchMap(({ productId, customerId }) => {
        return this.apollo
          .mutate<{ updateNextProduct }>({
            mutation: updateNextProductMutation,
            variables: { productId, customerId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            mergeMap(() => [
              new productActions.UpdateNextProductSuccess(productId),
              new fromBaseState.ShowInfo(
                notificationConfig.subscriptions.updateNextProduct.success
              )
            ]),
            catchError(err => [
              new productActions.UpdateNextProductFail(),
              new fromBaseState.ShowError(
                err.message ||
                  notificationConfig.subscriptions.updateNextProduct.error
              )
            ])
          );
      })
    )
  );
}
