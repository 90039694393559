import { Action } from '@ngrx/store';

import {
  FilterOptions,
  Pagination,
  PropertySearcherUserBean
} from '@ui/shared/models';

export const FETCH_PROPERTY_SEARCHER_USERS =
  '[Property Searcher Page] Fetch Users List';
export const FETCH_PROPERTY_SEARCHER_USERS_SUCCESS =
  '[Property Searcher Page] Fetch Users List Success';
export const FETCH_PROPERTY_SEARCHER_USERS_FAIL =
  '[Property Searcher Page] Fetch Users List Fail';

export class FetchPropertySearcherUsers implements Action {
  readonly type = FETCH_PROPERTY_SEARCHER_USERS;
  constructor(public filter: FilterOptions) {}
}

export class FetchPropertySearcherUsersSuccess implements Action {
  readonly type = FETCH_PROPERTY_SEARCHER_USERS_SUCCESS;
  constructor(
    public data: PropertySearcherUserBean[],
    public pagination: Pagination
  ) {}
}

export class FetchPropertySearcherUsersFail implements Action {
  readonly type = FETCH_PROPERTY_SEARCHER_USERS_FAIL;
  constructor(public error: string) {}
}

export const ACTIVATE_PROPERTY_SEARCHER =
  '[Property Searcher Page] Activate User ';
export const ACTIVATE_PROPERTY_SEARCHER_SUCCESS =
  '[Property Searcher Page] Activate User Success';
export const ACTIVATE_PROPERTY_SEARCHER_FAIL =
  '[Property Searcher Page] Activate User Fail';

export class ActivatePropertySearcher implements Action {
  readonly type = ACTIVATE_PROPERTY_SEARCHER;
  constructor(public email: string) {}
}

export class ActivatePropertySearcherSuccess implements Action {
  readonly type = ACTIVATE_PROPERTY_SEARCHER_SUCCESS;
}

export class ActivatePropertySearcherFail implements Action {
  readonly type = ACTIVATE_PROPERTY_SEARCHER_FAIL;
  constructor(public error: string) {}
}

export const IMPERSONATE_PROPERTY_SEARCHER =
  '[Property Searcher Page] Impersonate Property Searcher';
export const IMPERSONATE_PROPERTY_SEARCHER_SUCCESS =
  '[Property Searcher Page] Impersonate Property Searcher Success';
export const IMPERSONATE_PROPERTY_SEARCHER_FAIL =
  '[Property Searcher Page] Impersonate Property Searcher Fail';

export class ImpersonatePropertySearcher implements Action {
  readonly type = IMPERSONATE_PROPERTY_SEARCHER;
  constructor(public email: string) {}
}

export class ImpersonatePropertySearcherSuccess implements Action {
  readonly type = IMPERSONATE_PROPERTY_SEARCHER_SUCCESS;
}

export class ImpersonatePropertySearcherFail implements Action {
  readonly type = IMPERSONATE_PROPERTY_SEARCHER_FAIL;
  constructor(public error: string) {}
}

export const DELETE_PROPERTY_SEARCHER =
  '[Property Searcher Page] DELETE Property Searcher';
export const DELETE_PROPERTY_SEARCHER_SUCCESS =
  '[Property Searcher Page] DELETE Property Searcher Success';
export const DELETE_PROPERTY_SEARCHER_FAIL =
  '[Property Searcher Page] DELETE Property Searcher Fail';

export class DeletePropertySearcher implements Action {
  readonly type = DELETE_PROPERTY_SEARCHER;
  constructor(public id: string) {}
}

export class DeletePropertySearcherSuccess implements Action {
  readonly type = DELETE_PROPERTY_SEARCHER_SUCCESS;
}

export class DeletePropertySearcherFail implements Action {
  readonly type = DELETE_PROPERTY_SEARCHER_FAIL;
  constructor(public error: string) {}
}

export type PropertySearcherAction =
  | FetchPropertySearcherUsers
  | FetchPropertySearcherUsersSuccess
  | FetchPropertySearcherUsersFail
  | ActivatePropertySearcher
  | ActivatePropertySearcherSuccess
  | ActivatePropertySearcherFail
  | ImpersonatePropertySearcher
  | ImpersonatePropertySearcherSuccess
  | ImpersonatePropertySearcherFail
  | DeletePropertySearcher
  | DeletePropertySearcherSuccess
  | DeletePropertySearcherFail;
