<app-card [padding]="'none'">
  <div class="payment-details">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="title-m payment-details__title">
        {{ 'YOUR_PLAN_L' | translate }}
      </h3>
      <app-badge [color]="'light-green'">
        {{ 'general.renews_l' | translate }}
        {{ renewDate ? (renewDate | appDateTime) : '...' }}
      </app-badge>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h2 class="payment-details__amount">
          {{
            recalculatingPrice
              ? '...'
              : (totalPrice || 0.0 | currency: currency)
          }}/{{
            isYearlySelected
              ? ('general.year_l' | translate)
              : ('general.month_l' | translate)
          }}
        </h2>
        <p>
          {{
            recalculatingPrice
              ? '...'
              : (totalPriceNet || 0.0 | currency: currency)
          }}
          {{ 'general.net_price_information_l' | translate }}/{{
            isYearlySelected
              ? ('general.year_l' | translate)
              : ('general.month_l' | translate)
          }}
        </p>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-end">
          {{ 'YOUR_PLAN_NEXT_PAYMENT_PERIOD_L' | translate }}
        </div>
        <div class="payment-details__next_price_amount mb-1">
          {{
            recalculatingPrice
              ? '...'
              : (totalNextPrice || 0.0 | currency: currency)
          }}/{{
            isNextYearlySelected
              ? ('general.year_l' | translate)
              : ('general.month_l' | translate)
          }}
        </div>
        <div class="title-xs payment-details__subtitle justify-content-end">
          {{
            recalculatingPrice
              ? '...'
              : (totalPriceNet || 0.0 | currency: currency)
          }}
          {{ 'NET_PRICE_INFORMATION_L' | translate }} /{{
            isNextYearlySelected
              ? ('general.year_l' | translate)
              : ('general.month_l' | translate)
          }}
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h3 class="title-s payment-details__link">
          {{ 'BILLED_L' | translate
          }}<span class="selected-periodic">{{
            periodsMap[value?.subscriptionPeriod] | translate
          }}</span>
          @if (isYearlySelected) {
            <span class="selected-periodic"
              >({{
                'YEARLY_PRODUCT_DISCOUNT_INFO_L' | translate: yearlyDiscount
              }})</span
            >
          }
        </h3>
        <app-context-menu
          [tooltipPlacement]="ContextMenuTooltipPlacementEnum.BOTTOM"
        >
          <span menu-button class="dropdown-menu-button">
            <svg-icon
              src="/assets/images/icons/icon-dropdown.svg"
              [applyClass]="true"
              [svgStyle]="{ 'width.px': 15 }"
              class="secondary-svg-color rect polyline"
            ></svg-icon>
          </span>
          <div menu-content class="dropdown-list">
            @for (product of products; track product.id) {
              <app-context-menu-item (clickEvent)="onSelectProduct(product)"
                >{{ periodsMap[product?.subscriptionPeriod] | translate }}
              </app-context-menu-item>
            }
          </div>
        </app-context-menu>
        @if (nextProductSubscriptionPeriod) {
          <div class="payment-details__next-product">
            {{ 'subscription.next_product_l' | translate }}
            {{ periodsMap[nextProductSubscriptionPeriod] | translate }}
          </div>
        }
      </div>
      <div class="d-flex flex-column align-items-end">
        <div class="title-m">{{ 'PAYMENT_METHOD_L' | translate }}</div>
        <div class="d-flex">
          <div class="title-s">
            {{
              paymentMethod
                ? isInvoice
                  ? (paymentMethod.name | translate)
                  : paymentMethod.name
                : ('NONE_L' | translate)
            }}
          </div>
          <div>
            @if (paymentMethod) {
              {{ isInvoice ? '&nbsp;' : paymentMethod.shortInfo }}
            }
            @if (!isAdmin) {
              <a
                class="title-s payment-details__link"
                routerLink="/manage/payment-details"
              >
                {{ (paymentMethod ? 'CHANGE_L' : 'ADD_L') | translate }}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
    @if (form) {
      <hr class="pt-5" />
      <form [formGroup]="form">
        @if (isAdmin) {
          <div class="row ml0 mr0 mt20">
            <div class="col-12 col-md-3 col-lg-4 pl0 payment-details__section">
              <div class="d-flex payment-details__amount-container flex-column">
                <div class="title-s payment-details__subtitle">
                  {{ 'landlordProducts.price_without_discount_l' | translate }}
                </div>
                <div class="payment-details__year-amount-container d-flex">
                  <h2 class="payment-details__amount">
                    @if (!recalculatingPrice) {
                      <span>{{
                        totalPricePreDiscount || 0.0 | currency: currency
                      }}</span>
                    }
                    @if (recalculatingPrice) {
                      <span>...</span>
                    }
                  </h2>
                  <p class="payment-details__time-unit">
                    /{{
                      (isYearlySelected ? 'general.year_l' : 'general.month_l')
                        | translate
                    }}
                  </p>
                </div>
                <div class="payment-details__price-net-container">
                  @if (!recalculatingPrice) {
                    <span
                      >{{
                        totalPriceNetPreDiscount || 0.0 | currency: currency
                      }}
                    </span>
                  }
                  @if (recalculatingPrice) {
                    <span>...</span>
                  }
                  <span class="time-unit"
                    >{{ 'general.net_price_information_l' | translate }} /{{
                      (isYearlySelected ? 'general.year_l' : 'general.month_l')
                        | translate
                    }}</span
                  >
                </div>
              </div>
              <div class="d-flex payment-details__amount-container flex-column">
                <div class="title-xs mt15 mb0 payment-details__subtitle">
                  {{ 'YOUR_PLAN_NEXT_PAYMENT_PERIOD_L' | translate }}
                </div>
                <div class="payment-details__year-amount-container d-flex">
                  <h2 class="payment-details__next_price_amount">
                    @if (!recalculatingPrice) {
                      <span>{{
                        totalNextPricePreDiscount || 0.0 | currency: currency
                      }}</span>
                    }
                    @if (recalculatingPrice) {
                      <span>...</span>
                    }
                  </h2>
                  <p class="payment-details__next_price_time-unit">
                    /{{
                      (isNextYearlySelected
                        ? 'general.year_l'
                        : 'general.month_l'
                      ) | translate
                    }}
                  </p>
                </div>
                <div class="payment-details__next_price-net-container">
                  @if (!recalculatingPrice) {
                    <span
                      >{{
                        totalNextPriceNetPreDiscount || 0.0 | currency: currency
                      }}
                    </span>
                  }
                  @if (recalculatingPrice) {
                    <span>...</span>
                  }
                  <span class="time-unit"
                    >{{ 'general.net_price_information_l' | translate }} /{{
                      (isNextYearlySelected
                        ? 'general.year_l'
                        : 'general.month_l'
                      ) | translate
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-9 col-lg-8 pl0 d-flex">
              <div class="col-12 col-md payment-details__section">
                <app-form-field>
                  <div class="d-flex flex-row">
                    <app-form-field-label
                      >{{ 'general.discount_end_l' | translate }}
                    </app-form-field-label>
                  </div>
                  <div class="input-group mb10">
                    <app-date
                      formControlName="discountEnd"
                      [minDate]="minDateStruct"
                      [required]="false"
                    ></app-date>
                  </div>
                </app-form-field>
              </div>
              <div class="col-12 mx-1 col-md payment-details__section">
                <app-form-field>
                  <div class="d-flex flex-row">
                    <app-form-field-label
                      >{{ 'general.discount_l' | translate }}
                    </app-form-field-label>
                  </div>
                  <div class="input-group mb10">
                    <input
                      appInput
                      type="number"
                      [max]="ADDON_DISCOUNT_CONFIG.maxValue"
                      [min]="ADDON_DISCOUNT_CONFIG.minValue"
                      [step]="ADDON_DISCOUNT_CONFIG.stepValue"
                      formControlName="discount"
                      class="form-control"
                      [required]="discountEndDate?.length > 0"
                      [readonly]="isDisabled"
                    />
                  </div>
                </app-form-field>
              </div>
              <div class="col-12 me-1 col-md payment-details__section">
                <app-form-field>
                  <div class="d-flex flex-row">
                    <app-form-field-label
                      >{{ 'general.trial_l' | translate }}
                    </app-form-field-label>
                  </div>
                  <div class="input-group mb10">
                    <app-date
                      formControlName="trialPeriodEndDate"
                      [minDate]="minDateStruct"
                      [required]="isTrialPeriodRequired"
                      [readonly]="isTrialPeriodReadOnly"
                    ></app-date>
                  </div>
                </app-form-field>
              </div>
              <div class="col-12 col-md payment-details__section">
                <app-form-field>
                  <div class="d-flex flex-row">
                    <app-form-field-label
                      >{{ 'landlordProducts.multiply_factor_l' | translate }}
                    </app-form-field-label>
                  </div>
                  <div class="input-group mb10">
                    <input
                      appInput
                      type="number"
                      required
                      [placeholder]="
                        'landlordProducts.multiply_factor_l' | translate
                      "
                      formControlName="priceMultiplier"
                      class="form-control"
                      [readonly]="isMultiplierReadonly"
                    />
                  </div>
                  <div class="d-flex flex-row">
                    <app-form-field-label
                      >{{
                        'landlordProducts.next_multiply_factor_l' | translate
                      }}
                    </app-form-field-label>
                  </div>
                  <div class="input-group mb10">
                    <input
                      appInput
                      type="number"
                      [placeholder]="
                        'landlordProducts.next_multiply_factor_l' | translate
                      "
                      formControlName="nextPriceMultiplier"
                      class="form-control"
                    />
                  </div>
                </app-form-field>
              </div>
            </div>
          </div>
        }
      </form>
    }
  </div>
</app-card>
