import { Pagination, PropertySearcherUserBean } from '@ui/shared/models';

import * as fromActions from './property-searcher.actions';

export interface PropertySearcherState {
  usersData: PropertySearcherUserBean[];
  isLoading: boolean;
  loaded: boolean;
  error: string;
  pagination: Pagination;
}

export const initialState: PropertySearcherState = {
  usersData: [],
  isLoading: false,
  loaded: false,
  error: null,
  pagination: null
};

export function reducer(
  state = initialState,
  action: fromActions.PropertySearcherAction
): PropertySearcherState {
  switch (action.type) {
    case fromActions.FETCH_PROPERTY_SEARCHER_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        usersData: action.data,
        loaded: true,
        pagination: action.pagination
      };
    }

    case fromActions.ACTIVATE_PROPERTY_SEARCHER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loaded: true
      };
    }

    case fromActions.IMPERSONATE_PROPERTY_SEARCHER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loaded: true
      };
    }

    case fromActions.FETCH_PROPERTY_SEARCHER_USERS:
    case fromActions.ACTIVATE_PROPERTY_SEARCHER:
    case fromActions.IMPERSONATE_PROPERTY_SEARCHER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case fromActions.FETCH_PROPERTY_SEARCHER_USERS_FAIL:
    case fromActions.ACTIVATE_PROPERTY_SEARCHER_FAIL:
    case fromActions.IMPERSONATE_PROPERTY_SEARCHER_FAIL: {
      return {
        ...state,
        isLoading: false,
        loaded: false,
        error: action.error
      };
    }

    default:
      return state;
  }
}

export const getPropertySearcherUserData = (state: PropertySearcherState) =>
  state.usersData;
export const getPropertySearcherLoading = (state: PropertySearcherState) =>
  state.isLoading;
export const getPropertySearcherLoaded = (state: PropertySearcherState) =>
  state.loaded;
export const getError = (state: PropertySearcherState) => state.error;
export const getpagination = (state: PropertySearcherState) => state.pagination;
