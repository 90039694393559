import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromLandlordsReducer from './landlords/landlords.reducers';

export interface EditLandlordState {
  landlords: fromLandlordsReducer.LandlordState;
}

export const reducers: ActionReducerMap<EditLandlordState> = {
  landlords: fromLandlordsReducer.reducer
};

export const getLandlordState =
  createFeatureSelector<EditLandlordState>('editLandlord');
