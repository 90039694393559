import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-free-features-listing',
  templateUrl: './free-features-listing.component.html',
  styleUrls: ['./free-features-listing.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class FreeFeaturesListingComponent {}
