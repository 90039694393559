import { createSelector } from '@ngrx/store';

import { getLandlordState } from 'admin/+state/reducers';
import * as fromLandlordReducers from './landlord.reducers';

export const getUsersData = createSelector(
  getLandlordState,
  fromLandlordReducers.getLandlordUsersData
);

export const getCustomersData = createSelector(
  getLandlordState,
  fromLandlordReducers.getLandlordCustomersData
);

export const getInvoicesData = createSelector(
  getLandlordState,
  fromLandlordReducers.getLandlordInvoicesData
);

export const getLandlordActionState = createSelector(
  getLandlordState,
  fromLandlordReducers.getLandlordActionState
);

export const getLandlordPagination = createSelector(
  getLandlordState,
  fromLandlordReducers.getPagination
);
