<app-modal>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body downgrade-modal__body">
        <div class="downgrade-modal__content">
          <p class="downgrade-information">
            {{ 'DOWNGRADE_CUSTOMER_SIZE_MODAL_INFORMATION_L' | translate }}
          </p>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer downgrade-modal__footer">
        <app-button (clickEvent)="close()">
          {{ 'CLOSE_A' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
