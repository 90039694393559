import { Injectable, InjectionToken, inject } from '@angular/core';

import { Addon, AddonType, PriceTypes } from '@ui/shared/models';

export interface AddonsStrategy {
  freeAgents: number;
  agentType: AddonType.AGENT | string;
  homepageModuleRestType: AddonType.HOMEPAGE_MODULE_REST | string;
  customerCooperationType: AddonType.CUSTOMER_COOPERATION | string;
  notSupported?: AddonType[];
}

export const ADDONS_STRATEGY = new InjectionToken<AddonsStrategy>(
  'ADDONS_STRATEGY'
);

@Injectable()
export class AddonsHelperService {
  addonsStrategy = inject(ADDONS_STRATEGY);

  public setStrategy(strategy: AddonsStrategy) {
    this.addonsStrategy = {
      ...strategy,
      freeAgents: strategy.freeAgents || 0
    };
  }

  public extractAgent(addons: Addon[] = []): Addon {
    return addons?.find(addon => addon?.type === this.addonsStrategy.agentType);
  }

  public extractFreeAgent(addons: Addon[] = []): Addon {
    return addons?.find(addon => addon?.type === AddonType.FREE_AGENT);
  }

  public extractHomepageModule(addons: Addon[] = []): Addon {
    return addons?.find(
      addon => addon?.type === this.addonsStrategy.homepageModuleRestType
    );
  }

  public extractCustomerCooperation(addons: Addon[] = []): Addon {
    return addons?.find(
      addon => addon?.type === this.addonsStrategy.customerCooperationType
    );
  }

  public extractAgentDiscount(addons: Addon[]) {
    const agent = this.extractAgent(addons);
    return {
      value: agent?.discount?.value || 0,
      endDate: agent?.discount?.endDate || '',
      addonId: agent.id
    };
  }

  public extractFreeAgentDiscount(addons: Addon[]) {
    const agent = this.extractFreeAgent(addons);
    return {
      value: agent?.discount?.value || 0,
      endDate: agent?.discount?.endDate || '',
      addonId: agent?.id
    };
  }

  public extractHomepageModuleDiscount(addons: Addon[]) {
    const homepage = this.extractHomepageModule(addons);
    return {
      value: homepage?.discount?.value || 0,
      endDate: homepage?.discount?.endDate || '',
      addonId: homepage.id
    };
  }

  public extractCustomerCooperationDiscount(addons: Addon[]) {
    const customerCooperation = this.extractCustomerCooperation(addons);
    return {
      value: customerCooperation?.discount?.value || 0,
      endDate: customerCooperation?.discount?.endDate || '',
      addonId: customerCooperation.id
    };
  }

  public extractFeatureAddons(addons: Addon[] = []): Addon[] {
    return addons?.filter(
      addon =>
        addon?.type !== this.addonsStrategy.agentType &&
        addon?.type !== this.addonsStrategy.homepageModuleRestType &&
        addon?.type !== this.addonsStrategy.customerCooperationType &&
        addon?.type !== AddonType.FREE_AGENT
    );
  }

  public extractBookedAddons(addons: Addon[]) {
    return this.extractFeatureAddons(addons)?.filter(addon => addon?.booked);
  }

  public extractSupportedFeatureAddons(addons: Addon[] = []) {
    return this.extractFeatureAddons(addons)?.filter(
      addon => !this.addonsStrategy.notSupported.includes(addon?.type)
    );
  }

  public getAgentsPrice(
    addons: Addon[] = [],
    priceType: PriceTypes = PriceTypes.GROSS,
    includeDiscount = true,
    isYearly = false,
    isNext = false
  ) {
    const total = 0;
    const agent = this.extractAgent(addons);
    const appliedPrice = isNext ? agent?.nextPrice : agent?.price;
    if (!appliedPrice) {
      return total;
    }

    let preDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.monthlyPriceGross
        : appliedPrice.monthlyPriceNet;

    let postDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.postDiscountMonthlyPriceGross
        : appliedPrice.postDiscountMonthlyPriceNet;

    if (isYearly) {
      preDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.yearlyPriceGross
          : appliedPrice.yearlyPriceNet;

      postDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.postDiscountYearlyPriceGross
          : appliedPrice.postDiscountYearlyPriceNet;
    }

    // @TODO Maybe take renew in effect
    const price = includeDiscount ? postDiscountPrice : preDiscountPrice;
    const agentsToCalculate =
      agent.amounts.renew + (isNext ? 0 : agent.amounts.expire);
    return agentsToCalculate > this.addonsStrategy.freeAgents
      ? total + price * (agentsToCalculate - this.addonsStrategy.freeAgents)
      : total;
  }

  public getHomepageModulePrice(
    addons: Addon[] = [],
    priceType: PriceTypes = PriceTypes.GROSS,
    includeDiscount = true,
    isYearly = false,
    isNext = false
  ): number {
    const homepage = this.extractHomepageModule(addons);
    const appliedPrice = isNext ? homepage?.nextPrice : homepage?.price;
    if (!appliedPrice) {
      return 0;
    }

    let preDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.monthlyPriceGross
        : appliedPrice.monthlyPriceNet;

    let postDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.postDiscountMonthlyPriceGross
        : appliedPrice.postDiscountMonthlyPriceNet;

    if (isYearly) {
      preDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.yearlyPriceGross
          : appliedPrice.yearlyPriceNet;

      postDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.postDiscountYearlyPriceGross
          : appliedPrice.postDiscountYearlyPriceNet;
    }

    // @TODO Maybe take renew in effect
    const price = includeDiscount ? postDiscountPrice : preDiscountPrice;
    const homepagesToCalculate =
      (homepage?.amounts?.renew || 0) +
      (isNext ? 0 : homepage?.amounts?.expire || 0);
    return price * homepagesToCalculate;
  }

  public getCustomerCooperationPrice(
    addons: Addon[] = [],
    priceType: PriceTypes = PriceTypes.GROSS,
    includeDiscount = true,
    isYearly = false,
    isNext = false
  ): number {
    const customerCooperation = this.extractCustomerCooperation(addons);
    const appliedPrice = isNext
      ? customerCooperation?.nextPrice
      : customerCooperation?.price;
    if (!appliedPrice) {
      return 0;
    }

    let preDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.monthlyPriceGross
        : appliedPrice.monthlyPriceNet;

    let postDiscountPrice =
      priceType === PriceTypes.GROSS
        ? appliedPrice.postDiscountMonthlyPriceGross
        : appliedPrice.postDiscountMonthlyPriceNet;

    if (isYearly) {
      preDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.yearlyPriceGross
          : appliedPrice.yearlyPriceNet;

      postDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice.postDiscountYearlyPriceGross
          : appliedPrice.postDiscountYearlyPriceNet;
    }

    // @TODO Maybe take renew in effect
    const price = includeDiscount ? postDiscountPrice : preDiscountPrice;
    const customerCooperationsToCalculate =
      (customerCooperation?.amounts?.renew || 0) +
      (isNext ? 0 : customerCooperation?.amounts?.expire || 0);
    return price * customerCooperationsToCalculate;
  }

  public getAddonsPrice(
    addons: Addon[] = [],
    customerProductAddons: Addon[] = [],
    priceType: PriceTypes = PriceTypes.GROSS,
    includeDiscount = true,
    isYearly = false,
    isNext = false
  ): number {
    const oldBookedAddons =
      this.extractBookedAddons(customerProductAddons || addons) || [];
    const oldBookedAddonIds = oldBookedAddons.map(addon => addon.id);
    const bookedAddons = this.extractBookedAddons(addons) || [];
    const newBookedAddonsIds = bookedAddons
      .map(addon => addon.id)
      .filter(id => !oldBookedAddonIds.includes(id));
    const newBookedAddons = bookedAddons.filter(addon =>
      newBookedAddonsIds.includes(addon.id)
    );
    const addonsToCalculate = isNext
      ? bookedAddons
      : oldBookedAddons.concat(newBookedAddons);
    return addonsToCalculate.reduce((total, addon) => {
      const appliedPrice = isNext ? addon?.nextPrice : addon?.price;
      if (!appliedPrice) {
        return total;
      }

      let preDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice?.monthlyPriceGross
          : appliedPrice?.monthlyPriceNet;

      let postDiscountPrice =
        priceType === PriceTypes.GROSS
          ? appliedPrice?.postDiscountMonthlyPriceGross
          : appliedPrice?.postDiscountMonthlyPriceNet;

      if (isYearly) {
        preDiscountPrice =
          priceType === PriceTypes.GROSS
            ? appliedPrice?.yearlyPriceGross
            : appliedPrice?.yearlyPriceNet;

        postDiscountPrice =
          priceType === PriceTypes.GROSS
            ? appliedPrice?.postDiscountYearlyPriceGross
            : appliedPrice?.postDiscountYearlyPriceNet;
      }

      const price = includeDiscount ? postDiscountPrice : preDiscountPrice;
      return total + price;
    }, 0);
  }

  public getPrice(
    addons: Addon[] = [],
    customerProductAddons: Addon[] = [],
    priceType: PriceTypes = PriceTypes.GROSS,
    includeDiscount = true,
    isYearly = false,
    isNext = false
  ) {
    return (
      this.getCustomerCooperationPrice(
        addons,
        priceType,
        includeDiscount,
        isYearly,
        isNext
      ) +
      this.getHomepageModulePrice(
        addons,
        priceType,
        includeDiscount,
        isYearly,
        isNext
      ) +
      this.getAgentsPrice(
        addons,
        priceType,
        includeDiscount,
        isYearly,
        isNext
      ) +
      this.getAddonsPrice(
        addons,
        customerProductAddons,
        priceType,
        includeDiscount,
        isYearly,
        isNext
      )
    );
  }

  public getFreeAgentDefault(isYearly: boolean) {
    const addonId = isYearly ? '200067' : '200066';
    return {
      id: addonId,
      amounts: {
        renew: 0,
        expire: 0
      },
      discount: {
        addonId,
        value: 0,
        endDate: null
      },
      type: AddonType.FREE_AGENT,
      price: {
        weeklyPriceNet: 0,
        monthlyPriceNet: 0,
        yearlyPriceNet: 0,
        postDiscountWeeklyPriceNet: 0,
        postDiscountMonthlyPriceNet: 0,
        postDiscountYearlyPriceNet: 0,
        weeklyPriceGross: 0,
        monthlyPriceGross: 0,
        yearlyPriceGross: 0,
        postDiscountWeeklyPriceGross: 0,
        postDiscountMonthlyPriceGross: 0,
        postDiscountYearlyPriceGross: 0,
        setup: null,
        vat: 1.19
      },
      nextPrice: {
        weeklyPriceNet: 0,
        monthlyPriceNet: 0,
        yearlyPriceNet: 0,
        postDiscountWeeklyPriceNet: 0,
        postDiscountMonthlyPriceNet: 0,
        postDiscountYearlyPriceNet: 0,
        weeklyPriceGross: 0,
        monthlyPriceGross: 0,
        yearlyPriceGross: 0,
        postDiscountWeeklyPriceGross: 0,
        postDiscountMonthlyPriceGross: 0,
        postDiscountYearlyPriceGross: 0,
        setup: null,
        vat: 1.19
      },
      name: 'ADDON_TITLE_FREE_AGENT_L',
      description: 'ADDON_DESCRIPTION_FREE_AGENT_L',
      renew: null,
      booked: false
    };
  }
}
