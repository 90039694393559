import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromLandlordsReducers from './landlords.reducers';

export const getLandlordsState = createSelector(
  fromReducers.getLandlordState,
  (state: fromReducers.EditLandlordState) => state.landlords
);

export const getCRUDActionState = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getCRUDActionState
);

export const getLandlordActionState = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getLandlordActionState
);

export const getSelectedLandlordId = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getSelectedLandlordId
);

export const getLandlord = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getLandlord
);

export const getNextProductId = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getNextProductId
);

export const getCurrentStepNumber = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getCurrentStepNumber
);

export const getCompletedStep = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getCompletedStep
);

export const getObjectHierarchy = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getObjectHierarchy
);

export const getCustomerImportSettings = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getCustomerImportSettings
);

export const getObjectHierarchyLoadingStatus = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getObjectHierarchyLoadingStatus
);

export const getObjectHierarchyActionState = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getObjectHierarchyActionState
);

export const getCustomerImportSettingsActionState = createSelector(
  getLandlordsState,
  fromLandlordsReducers.getCustomerImportSettingsActionState
);
