import { createSelector } from '@ngrx/store';

import { getPortalsState } from '../reducers';
import * as fromPortalsReducers from './portals.reducers';

export const getPortalsLoading = createSelector(
  getPortalsState,
  fromPortalsReducers.getPortalsLoading
);

export const getPortalsLoaded = createSelector(
  getPortalsState,
  fromPortalsReducers.getPortalsLoaded
);

export const getPortalsLoadingError = createSelector(
  getPortalsState,
  fromPortalsReducers.getPortalsLoadingError
);

export const getLinkedPortalsEntities = createSelector(
  getPortalsState,
  fromPortalsReducers.getLinkedPortals
);

export const getLinkedPortals = createSelector(
  getLinkedPortalsEntities,
  entities => Object.keys(entities).map(key => entities[key])
);
