import { PortalCredentials } from '@ui/shared/models';
import { arrayToObject } from 'libs/utils';
import * as fromActions from './portals.actions';

export interface PortalsState {
  loading: boolean;
  loaded: boolean;
  loadingError: string | null;
  linkedPortals: { [id: string]: PortalCredentials };
}

export const initialState: PortalsState = {
  loading: false,
  loaded: false,
  loadingError: null,
  linkedPortals: {}
};

export function reducer(
  state = initialState,
  action: fromActions.PortalAction
): PortalsState {
  switch (action.type) {
    case fromActions.FETCH_PORTALS: {
      return {
        ...state,
        loaded: false,
        loading: true,
        loadingError: null
      };
    }

    case fromActions.FETCH_PORTALS_SUCCESS: {
      const linkedPortals = arrayToObject(action.portals);

      return {
        ...state,
        loaded: true,
        loading: false,
        linkedPortals
      };
    }

    case fromActions.FETCH_PORTALS_FAIL: {
      return {
        ...state,
        loading: false,
        loadingError: action.error
      };
    }

    default: {
      return state;
    }
  }
}

export const getPortalsLoaded = (state: PortalsState) => state.loaded;
export const getPortalsLoading = (state: PortalsState) => state.loading;
export const getPortalsLoadingError = (state: PortalsState) =>
  state.loadingError;
export const getLinkedPortals = (state: PortalsState) => state.linkedPortals;
