import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Prioset } from '@ui/shared/models';
import * as fromBaseState from 'libs/infrastructure/base-state';

import { notificationConfig as notification } from 'admin/config';

import { UpdatePriosetInput } from '../queries/prioset.mutation';

import { PriosetFacade } from '../service';
import * as fromActions from './priosets.actions';

@Injectable()
export class PriosetsEffects {
  private actions$ = inject(Actions);
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private priosetFacade = inject(PriosetFacade);

  priosetUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_PRIOSET),
      map((action: fromActions.UpdatePrioset) =>
        this.getUpdatePriosetInput(action.prioset)
      ),
      switchMap(input =>
        this.priosetFacade.updatePrioset(input).pipe(
          map(result => new fromActions.UpdatePriosetSuccess(result)),
          catchError(() =>
            of(
              new fromActions.UpdatePriosetFail(
                notification.prioset.update.error
              )
            )
          )
        )
      )
    )
  );

  priosetUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_PRIOSET_SUCCESS),
      mergeMap(() => [
        new fromBaseState.ShowInfo(notification.prioset.update.success),
        new fromActions.ClearLatestSavedPrioset()
      ])
    )
  );

  priosetFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.UpdatePriosetFail>(fromActions.UPDATE_PRIOSET_FAIL),
      map(action => new fromBaseState.ShowError(action.error))
    )
  );

  private getUpdatePriosetInput(prioset: Prioset): UpdatePriosetInput {
    const {
      name,
      description,
      data,
      id: priosetID,
      template = false
    } = prioset;
    return { name, description, data, template, locked: false, priosetID };
  }
}
