import {
  Addon,
  AddonDiscount,
  AddonPrices,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage
} from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import * as fromActions from './products.actions';

export interface ProductsState {
  selectedProductId: string;
  landlordProducts: { [key: string]: ProductOverview };
  landlordProductsActionState: ActionState;
  recalculatingPricesActionState: ActionState;
  defaultProductId: string; // default product is used for new users, without CustomerProduct
  defaultProducts: ProductOverview[];
  defaultProductsActionState: ActionState;
  addonDiscounts: AddonDiscount[];
  digitalContractQuotaDiscounts: QuotaDiscount[];
  digitalContractQuotaPackages: QuotaPackage[];
  objectContingentQuotaDiscounts: QuotaDiscount[];
  objectContingentQuotaPackages: QuotaPackage[];
  subscriptionActionState: ActionState;
}

export const initialState: ProductsState = {
  landlordProducts: {},
  landlordProductsActionState: ActionStateCreator.create(),
  defaultProducts: [],
  defaultProductsActionState: ActionStateCreator.create(),
  defaultProductId: '100001',
  selectedProductId: null,
  recalculatingPricesActionState: ActionStateCreator.create(),
  addonDiscounts: [],
  digitalContractQuotaDiscounts: [],
  digitalContractQuotaPackages: [],
  objectContingentQuotaDiscounts: [],
  objectContingentQuotaPackages: [],
  subscriptionActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.SubscriptionAction
) {
  switch (action.type) {
    case fromActions.LOAD_CUSTOMER_PRODUCT: {
      return {
        ...state,
        landlordProductsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_CUSTOMER_PRODUCT_FAIL: {
      return {
        ...state,
        landlordProductsActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.LOAD_CUSTOMER_PRODUCT_SUCCESS: {
      const landlordProducts = {
        ...state.landlordProducts,
        [action.customerID]: action.product
      };
      const addonDiscounts = action?.product?.addons?.map(addon => ({
        ...addon?.discount,
        addonId: addon.id
      }));
      return {
        ...state,
        landlordProducts,
        defaultProductId: action.product?.product?.id || state.defaultProductId,
        landlordProductsActionState: ActionStateCreator.onSuccess(),
        addonDiscounts
      };
    }

    case fromActions.LOAD_AVAILABLE_PRODUCTS: {
      return {
        ...state,
        defaultProductsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_AVAILABLE_PRODUCTS_SUCCESS: {
      const defaultProducts = action.products;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        defaultProducts,
        defaultProductsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_AVAILABLE_PRODUCTS_FAIL: {
      return {
        ...state,
        defaultProductsActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SELECT_DEFAULT_PRODUCT: {
      const defaultProductId = action.productId;

      return {
        ...state,
        defaultProductId
      };
    }

    case fromActions.SELECT_PRODUCT: {
      const selectedProductId = action.productId;

      return {
        ...state,
        selectedProductId
      };
    }

    case fromActions.LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT_SUCCESS: {
      return {
        ...state,
        digitalContractQuotaDiscounts: action.quotaDiscount
      };
    }

    case fromActions.LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES_SUCCESS: {
      return {
        ...state,
        digitalContractQuotaPackages: action.quotaPackages
      };
    }

    case fromActions.LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT_SUCCESS: {
      return {
        ...state,
        objectContingentQuotaDiscounts: action.quotaDiscount
      };
    }

    case fromActions.LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES_SUCCESS: {
      return {
        ...state,
        objectContingentQuotaPackages: action.quotaPackages
      };
    }

    case fromActions.RECALCULATE_ADDON_PRICE: {
      return {
        ...state,
        recalculatingPricesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.RECALCULATE_ADDON_PRICE_FAIL: {
      return {
        ...state,
        recalculatingPricesActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.RECALCULATE_ADDON_PRICE_SUCCESS: {
      let landlordProduct = state.landlordProducts[action.customerId];
      const isInProduct = landlordProduct.addons.some(
        a => a.id === action.addonId
      );
      const addons = isInProduct
        ? changePriceOfAddon(landlordProduct.addons, action)
        : [
            ...landlordProduct.addons,
            {
              id: action.addonId,
              price: action.prices.price,
              nextPrice: action.prices.nextPrice
            }
          ];
      landlordProduct = {
        ...landlordProduct,
        addons
      };
      return {
        ...state,
        recalculatingPricesActionState: ActionStateCreator.onSuccess(),
        landlordProducts: {
          ...state.landlordProducts,
          [action.customerId]: landlordProduct
        }
      };
    }

    case fromActions.RECALCULATE_ADDONS_PRICE: {
      return {
        ...state,
        recalculatingPricesActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.RECALCULATE_ADDONS_PRICE_FAIL: {
      return {
        ...state,
        recalculatingPricesActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.RECALCULATE_ADDONS_PRICE_SUCCESS: {
      let landlordProduct = state.landlordProducts[action.customerId];
      let defaultProducts = state.defaultProducts;
      landlordProduct = {
        ...landlordProduct,
        addons: changePriceOfMultipleAddons(
          landlordProduct.addons,
          action.addons
        )
      };

      defaultProducts = defaultProducts.map(p => {
        if (
          p.product.id ===
          state.landlordProducts[action.customerId]?.product?.id
        ) {
          return {
            ...p,
            addons: changePriceOfMultipleAddons(p.addons, action.addons)
          };
        }
        return p;
      });
      return {
        ...state,
        landlordProducts: {
          ...state.landlordProducts,
          [action.customerId]: landlordProduct
        },
        defaultProducts,
        recalculatingPricesActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.UPDATE_SUBSCRIPTION:
    case fromActions.CREATE_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.UPDATE_SUBSCRIPTION_FAIL:
    case fromActions.CREATE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscriptionActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.UPDATE_SUBSCRIPTION_SUCCESS:
    case fromActions.CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionActionState: ActionStateCreator.onSuccess(),
        landlordProducts: {
          ...state.landlordProducts,
          [action.customerID]: action.product
        }
      };
    }

    case fromActions.UPDATE_NEXT_PRODUCT_SUCCESS: {
      return {
        ...state,
        nextProductId: action.productId
      };
    }

    case fromActions.RESET_PRODUCT: {
      return {
        ...initialState,
        addonDiscounts: []
      };
    }

    case fromActions.CREATE_PRODUCT: {
      return {
        ...state,
        landlordProducts: {
          ...state.landlordProducts,
          new: state.defaultProducts.find(
            p => p.product.id === state.defaultProductId
          )
        },
        landlordProductsActionState: ActionStateCreator.onSuccess()
      };
    }

    default: {
      return state;
    }
  }
}

function changePriceOfMultipleAddons(
  addons: Addon[],
  newAddon: Addon[]
): Addon[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (addons || []).map(a => {
    const foundIndex = newAddon.findIndex(na => na.id === a.id);
    if (foundIndex > -1) {
      return {
        ...a,
        price: newAddon[foundIndex].price,
        nextPrice: newAddon[foundIndex].nextPrice
      };
    }
    return a;
  });
}

function changePriceOfAddon(
  addons: Addon[],
  action: { addonId: string; prices: AddonPrices }
): Addon[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (addons || []).map(ca => {
    if (ca.id === action.addonId) {
      return {
        ...ca,
        price: action.prices.price,
        nextPrice: action.prices.nextPrice
      };
    }
    return ca;
  });
}

export const getLandlordProducts = (state: ProductsState) =>
  state.landlordProducts;
export const getLandlordProductsActionState = (state: ProductsState) =>
  state.landlordProductsActionState;
export const getDefaultProducts = (state: ProductsState) =>
  state.defaultProducts;
export const getDefaultProductsActionState = (state: ProductsState) =>
  state.defaultProductsActionState;
export const getSelectedProductId = (state: ProductsState) =>
  state.selectedProductId;
export const getDefaultProductId = (state: ProductsState) =>
  state.defaultProductId;
export const getRecalculatingPricesActionState = (state: ProductsState) =>
  state.recalculatingPricesActionState;
export const getAddonDiscounts = (state: ProductsState) => state.addonDiscounts;
export const getDigitalContractQuotaDiscounts = (state: ProductsState) =>
  state.digitalContractQuotaDiscounts;
export const getDigitalContractQuotaPackages = (state: ProductsState) =>
  state.digitalContractQuotaPackages;
export const getObjectContingentQuotaDiscounts = (state: ProductsState) =>
  state.objectContingentQuotaDiscounts;
export const getObjectContingentQuotaPackages = (state: ProductsState) =>
  state.objectContingentQuotaPackages;
export const getMapOfValidAddonDiscounts = (state: ProductsState) => {
  return;
  state.addonDiscounts.filter(
    discount => new Date(discount.endDate).getTime() > new Date().getTime()
  );
};
export const getSubscriptionActionState = (state: ProductsState) =>
  state.subscriptionActionState;
