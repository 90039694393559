import { NgModule, ModuleWithProviders } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ComponentsModule } from 'libs/components';
import { PipesModule } from 'libs/pipes';

import { SubscriptionPageComponent } from 'libs/products/subscription-page.component';
import { RouterModule } from '@angular/router';
import {
  ProductsModuleConfig,
  DEFAULT_ADDONS_STRATEGY
} from './products-module.config';

import {
  AddonsHelperService,
  ADDONS_STRATEGY,
  SubscriptionsDiffService
} from './services';

import {
  AddonComponent,
  DowngradeModalComponent,
  FreeFeaturesListingComponent,
  PaymentDetailsLibComponent,
  SubscriptionDetailsComponent,
  SubscriptionFormComponent,
  UpdateSubscriptionModalComponent
} from './components';

const services = [AddonsHelperService, SubscriptionsDiffService];

const components = [
  AddonComponent,
  SubscriptionDetailsComponent,
  SubscriptionPageComponent,
  SubscriptionFormComponent,
  PaymentDetailsLibComponent,
  DowngradeModalComponent,
  UpdateSubscriptionModalComponent,
  FreeFeaturesListingComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ComponentsModule,
    PipesModule,
    RouterModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ...components
  ],
  exports: [...components]
})
export class ProductsModule {
  public static forRoot(
    config: ProductsModuleConfig = {}
  ): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule,
      providers: [
        {
          provide: ADDONS_STRATEGY,
          useValue: config.addonsStrategy || DEFAULT_ADDONS_STRATEGY
        },
        ...services
      ]
    };
  }
}
