import { AddonType } from '@ui/shared/models';

import { AddonsStrategy } from './services';

export const DEFAULT_ADDONS_STRATEGY: AddonsStrategy = {
  freeAgents: 1,
  agentType: AddonType.AGENT,
  homepageModuleRestType: AddonType.HOMEPAGE_MODULE_REST,
  customerCooperationType: AddonType.CUSTOMER_COOPERATION,
  notSupported: [AddonType.DATAINSIGHTS, AddonType.SHORTLIST]
};

export const ADDON_DISCOUNT_CONFIG = {
  maxValue: 1,
  minValue: -1,
  stepValue: 0.05
};

export interface ProductsModuleConfig {
  addonsStrategy?: AddonsStrategy;
}
