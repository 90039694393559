import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromPortalReducer from './portals/portals.reducers';
import * as fromPriosetReducer from './priosets/priosets.reducers';
import * as fromSubscriptionsReducer from './products/products.reducers';
import * as fromLandlordReducer from './landlord/landlord.reducers';
import * as fromPropertySearcherReducer from './property-searcher/property-searcher.reducers';

export interface AppState {
  portals: fromPortalReducer.PortalsState;
  prioset: fromPriosetReducer.PriosetsState;
  products: fromSubscriptionsReducer.ProductsState;
  landlord: fromLandlordReducer.LandlordState;
  propertySearcher: fromPropertySearcherReducer.PropertySearcherState;
}

export const appReducers: ActionReducerMap<AppState> = {
  portals: fromPortalReducer.reducer,
  prioset: fromPriosetReducer.reducer,
  products: fromSubscriptionsReducer.reducer,
  landlord: fromLandlordReducer.reducer,
  propertySearcher: fromPropertySearcherReducer.reducer
};

export const getPortalsState =
  createFeatureSelector<fromPortalReducer.PortalsState>('portals');
export const getPriosetState =
  createFeatureSelector<fromPriosetReducer.PriosetsState>('prioset');
export const getProductsState =
  createFeatureSelector<fromSubscriptionsReducer.ProductsState>('products');
export const getLandlordState =
  createFeatureSelector<fromLandlordReducer.LandlordState>('landlord');
export const getPropertySearcherState =
  createFeatureSelector<fromPropertySearcherReducer.PropertySearcherState>(
    'propertySearcher'
  );
