import { Injectable, inject } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';

import { ApolloQueryResult } from '@apollo/client/core';
import {
  PriosetsUpdateResult,
  UpdatePriosetInput,
  updatePriosetMutation
} from '../queries';

@Injectable()
export class PriosetFacade {
  private apollo = inject(Apollo);

  public updatePrioset(input: UpdatePriosetInput) {
    return this.apollo
      .mutate({
        mutation: updatePriosetMutation,
        variables: { input }
      })
      .pipe(
        map(
          (response: ApolloQueryResult<PriosetsUpdateResult>) =>
            response.data && response.data.updatePrioset
        )
      );
  }
}
