import { Action } from '@ngrx/store';

import {
  Addon,
  AddonDiscount,
  AddonPriceCalculation,
  AddonPrices,
  CreateSubscriptionPayload,
  ProductOverview,
  QuotaDiscount,
  QuotaPackage,
  UpdateSubscriptionsPayload
} from '@ui/shared/models';

export const LOAD_CUSTOMER_PRODUCT = '[Product] Load Customer Product';
export const LOAD_CUSTOMER_PRODUCT_FAIL =
  '[Product] Load Customer Product Fail';
export const LOAD_CUSTOMER_PRODUCT_SUCCESS =
  '[Product] Load Customer Product Success';

export class LoadCustomerProduct implements Action {
  readonly type = LOAD_CUSTOMER_PRODUCT;
  constructor(public customerID: string) {}
}

export class LoadCustomerProductFail implements Action {
  readonly type = LOAD_CUSTOMER_PRODUCT_FAIL;

  constructor(public error: any) {}
}

export class LoadCustomerProductSuccess implements Action {
  readonly type = LOAD_CUSTOMER_PRODUCT_SUCCESS;

  constructor(
    public product: ProductOverview,
    public customerID: string
  ) {}
}

export const LOAD_AVAILABLE_PRODUCTS = '[Products] Load Available Products';
export const LOAD_AVAILABLE_PRODUCTS_FAIL =
  '[Products] Load Available Products Fail';
export const LOAD_AVAILABLE_PRODUCTS_SUCCESS =
  '[Products] Load Available Products Success';

export class LoadAvailableProducts implements Action {
  readonly type = LOAD_AVAILABLE_PRODUCTS;
  constructor(public multiplier?: number) {}
}

export class LoadAvailableProductsFail implements Action {
  readonly type = LOAD_AVAILABLE_PRODUCTS_FAIL;

  constructor(public error: any) {}
}

export class LoadAvailableProductsSuccess implements Action {
  readonly type = LOAD_AVAILABLE_PRODUCTS_SUCCESS;
  constructor(public products: ProductOverview[]) {}
}

export const SELECT_DEFAULT_PRODUCT = '[Products] Select Default Product';
export const SELECT_PRODUCT = '[Products] Select Product';
export const SET_PRICE_MULTIPLIER = '[Products] Set Price Multiplier';
export const SET_DISCOUNT = '[Products] Set Discount';

export class SelectDefaultProduct implements Action {
  readonly type = SELECT_DEFAULT_PRODUCT;

  constructor(public productId: string) {}
}

export class SelectProduct implements Action {
  readonly type = SELECT_PRODUCT;

  constructor(public productId: string) {}
}

export const LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT =
  '[Products] Load Digital Contract Quota Discount';
export const LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT_FAIL =
  '[Products] Load Digital Contract Quota Discount Fail';
export const LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT_SUCCESS =
  '[Products] Load Digital Contract Quota Discount Success';

export class LoadDigitalContractQuotaDiscount implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT;

  constructor(public customerId: string) {}
}

export class LoadDigitalContractQuotaDiscountFail implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT_FAIL;

  constructor(public error: Error) {}
}

export class LoadDigitalContractQuotaDiscountSuccess implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_DISCOUNT_SUCCESS;

  constructor(public quotaDiscount: QuotaDiscount[]) {}
}

export const LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES =
  '[Products] Load Digital Contract Quota Packages';
export const LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES_FAIL =
  '[Products] Load Digital Contract Quota Packages Fail';
export const LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES_SUCCESS =
  '[Products] Load Digital Contract Quota Packages Success';

export class LoadDigitalContractQuotaPackages implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES;

  constructor(public customerId: string) {}
}

export class LoadDigitalContractQuotaPackagesFail implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES_FAIL;

  constructor(public error: Error) {}
}

export class LoadDigitalContractQuotaPackagesSuccess implements Action {
  readonly type = LOAD_DIGITAL_CONTRACT_QUOTA_PACKAGES_SUCCESS;

  constructor(public quotaPackages: QuotaPackage[]) {}
}

export const SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT =
  '[Products] Save Digital Contract Quota Discount';
export const SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT_FAIL =
  '[Products] Save Digital Contract Quota Discount Fail';
export const SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT_SUCCESS =
  '[Products] Save Digital Contract Quota Discount Success';

export class SaveDigitalContractQuotaDiscount implements Action {
  readonly type = SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT;

  constructor(
    public customerId: string,
    public discounts: QuotaDiscount[]
  ) {}
}

export class SaveDigitalContractQuotaDiscountFail implements Action {
  readonly type = SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT_FAIL;

  constructor(public error: Error) {}
}

export class SaveDigitalContractQuotaDiscountSuccess implements Action {
  readonly type = SAVE_DIGITAL_CONTRACT_QUOTA_DISCOUNT_SUCCESS;
}

export const LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT =
  '[Products] Load Object Contingent Quota Discount';
export const LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT_FAIL =
  '[Products] Load Object Contingent Quota Discount Fail';
export const LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT_SUCCESS =
  '[Products] Load Object Contingent Quota Discount Success';

export class LoadObjectContingentQuotaDiscount implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT;

  constructor(public customerId: string) {}
}

export class LoadObjectContingentQuotaDiscountFail implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT_FAIL;

  constructor(public error: Error) {}
}

export class LoadObjectContingentQuotaDiscountSuccess implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_DISCOUNT_SUCCESS;

  constructor(public quotaDiscount: QuotaDiscount[]) {}
}

export const LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES =
  '[Products] Load Object Contingent Quota Packages';
export const LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES_FAIL =
  '[Products] Load Object Contingent Quota Packages Fail';
export const LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES_SUCCESS =
  '[Products] Load Object Contingent Quota Packages Success';

export class LoadObjectContingentQuotaPackages implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES;

  constructor(public customerId: string) {}
}

export class LoadObjectContingentQuotaPackagesFail implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES_FAIL;

  constructor(public error: Error) {}
}

export class LoadObjectContingentQuotaPackagesSuccess implements Action {
  readonly type = LOAD_OBJECT_CONTINGENT_QUOTA_PACKAGES_SUCCESS;

  constructor(public quotaPackages: QuotaPackage[]) {}
}

export const SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT =
  '[Products] Save Object Contingent Quota Discount';
export const SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT_FAIL =
  '[Products] Save Object Contingent Quota Discount Fail';
export const SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT_SUCCESS =
  '[Products] Save Object Contingent Quota Discount Success';

export class SaveObjectContingentQuotaDiscount implements Action {
  readonly type = SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT;

  constructor(
    public customerId: string,
    public discounts: QuotaDiscount[]
  ) {}
}

export class SaveObjectContingentQuotaDiscountFail implements Action {
  readonly type = SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT_FAIL;

  constructor(public error: Error) {}
}

export class SaveObjectContingentQuotaDiscountSuccess implements Action {
  readonly type = SAVE_OBJECT_CONTINGENT_QUOTA_DISCOUNT_SUCCESS;
}

export const DEACTIVATE_PRODUCT_RENEW =
  '[Landlord Page] Deactivate Product Renew';
export const DEACTIVATE_PRODUCT_RENEW_SUCCESS =
  '[Landlord Page] Deactivate Product Renew Success';
export const DEACTIVATE_PRODUCT_RENEW_FAIL =
  '[Landlord Page] Deactivate Product Renew Fail';

export class DeactivateProductRenew implements Action {
  readonly type = DEACTIVATE_PRODUCT_RENEW;

  constructor(
    public id: string,
    public dueDate?: string
  ) {}
}

export class DeactivateProductRenewFail implements Action {
  readonly type = DEACTIVATE_PRODUCT_RENEW_FAIL;

  constructor(public error: any) {}
}

export class DeactivateProductRenewSuccess implements Action {
  readonly type = DEACTIVATE_PRODUCT_RENEW_SUCCESS;
}

export const RECALCULATE_ADDON_PRICE = '[Product] Recalculate Addon Price';
export const RECALCULATE_ADDON_PRICE_FAIL =
  '[Product] Recalculate Addon Price Fail';
export const RECALCULATE_ADDON_PRICE_SUCCESS =
  '[Product] Recalculate Addon Price Success';

export class RecalculateAddonPrice implements Action {
  readonly type = RECALCULATE_ADDON_PRICE;
  constructor(public data: AddonPriceCalculation) {}
}
export class RecalculateAddonPriceFail implements Action {
  readonly type = RECALCULATE_ADDON_PRICE_FAIL;
  constructor(public error: any) {}
}
export class RecalculateAddonPriceSuccess implements Action {
  readonly type = RECALCULATE_ADDON_PRICE_SUCCESS;
  constructor(
    public addonId: string,
    public prices: AddonPrices,
    public customerId: string
  ) {}
}

export const RECALCULATE_ADDONS_PRICE = '[Product] Recalculate Addons Price';
export const RECALCULATE_ADDONS_PRICE_FAIL =
  '[Product] Recalculate Addons Price Fail';
export const RECALCULATE_ADDONS_PRICE_SUCCESS =
  '[Product] Recalculate Addons Price Success';

export class RecalculateAddonsPrice implements Action {
  readonly type = RECALCULATE_ADDONS_PRICE;
  constructor(
    public data: {
      addonDiscounts: AddonDiscount[];
      discount: number;
      discountEndDate?: Date;
      priceMultiplier: number;
      nextPriceMultiplier?: number;
    }
  ) {}
}
export class RecalculateAddonsPriceFail implements Action {
  readonly type = RECALCULATE_ADDONS_PRICE_FAIL;
  constructor(public error: any) {}
}
export class RecalculateAddonsPriceSuccess implements Action {
  readonly type = RECALCULATE_ADDONS_PRICE_SUCCESS;
  constructor(
    public addons: Addon[],
    public customerId: string
  ) {}
}

export const CREATE_SUBSCRIPTION = '[Product] Create Subscription';
export const CREATE_SUBSCRIPTION_FAIL = '[Product] Create Subscription Fail';
export const CREATE_SUBSCRIPTION_SUCCESS =
  '[Product] Create Subscription Success';

export class CreateSubscription implements Action {
  readonly type = CREATE_SUBSCRIPTION;
  constructor(
    public payload: CreateSubscriptionPayload,
    public userId: string
  ) {}
}

export class CreateSubscriptionFail implements Action {
  readonly type = CREATE_SUBSCRIPTION_FAIL;

  constructor(public error: any) {}
}

export class CreateSubscriptionSuccess implements Action {
  readonly type = CREATE_SUBSCRIPTION_SUCCESS;

  constructor(
    public product: ProductOverview,
    public customerID: string
  ) {}
}

export const CREATE_PRODUCT = '[Product] Create Product';

export class CreateProduct implements Action {
  readonly type = CREATE_PRODUCT;
}

export const UPDATE_SUBSCRIPTION = '[Product] Update Subscription';
export const UPDATE_SUBSCRIPTION_FAIL = '[Product] Update Subscription Fail';
export const UPDATE_SUBSCRIPTION_SUCCESS =
  '[Product] Update Subscription Success';

export class UpdateSubscription implements Action {
  readonly type = UPDATE_SUBSCRIPTION;

  constructor(
    public payload: UpdateSubscriptionsPayload,
    public userId: string
  ) {}
}

export class UpdateSubscriptionFail implements Action {
  readonly type = UPDATE_SUBSCRIPTION_FAIL;

  constructor(public error: any) {}
}

export class UpdateSubscriptionSuccess implements Action {
  readonly type = UPDATE_SUBSCRIPTION_SUCCESS;
  constructor(
    public product: ProductOverview,
    public customerID: string
  ) {}
}

export const UPDATE_NEXT_PRODUCT = '[Product] Update Next Product';
export const UPDATE_NEXT_PRODUCT_FAIL = '[Product] Update Next Product Fail';
export const UPDATE_NEXT_PRODUCT_SUCCESS =
  '[Product] Update Next Product Success';

export class UpdateNextProduct implements Action {
  readonly type = UPDATE_NEXT_PRODUCT;

  constructor(
    public productId: string,
    public customerId: string
  ) {}
}

export class UpdateNextProductFail implements Action {
  readonly type = UPDATE_NEXT_PRODUCT_FAIL;
}

export class UpdateNextProductSuccess implements Action {
  readonly type = UPDATE_NEXT_PRODUCT_SUCCESS;

  constructor(public productId: string) {}
}

export const RESET_PRODUCT = '[Product] Reset Product';

export class ResetProduct implements Action {
  readonly type = RESET_PRODUCT;
}

export type SubscriptionAction =
  | LoadCustomerProduct
  | LoadCustomerProductFail
  | LoadCustomerProductSuccess
  | LoadAvailableProducts
  | LoadAvailableProductsFail
  | LoadAvailableProductsSuccess
  | SelectProduct
  | SelectDefaultProduct
  | LoadDigitalContractQuotaDiscount
  | LoadDigitalContractQuotaDiscountFail
  | LoadDigitalContractQuotaDiscountSuccess
  | LoadDigitalContractQuotaPackages
  | LoadDigitalContractQuotaPackagesFail
  | LoadDigitalContractQuotaPackagesSuccess
  | LoadObjectContingentQuotaDiscount
  | LoadObjectContingentQuotaDiscountFail
  | LoadObjectContingentQuotaDiscountSuccess
  | LoadObjectContingentQuotaPackages
  | LoadObjectContingentQuotaPackagesFail
  | LoadObjectContingentQuotaPackagesSuccess
  | SaveObjectContingentQuotaDiscount
  | SaveObjectContingentQuotaDiscountFail
  | SaveObjectContingentQuotaDiscountSuccess
  | DeactivateProductRenew
  | DeactivateProductRenewSuccess
  | DeactivateProductRenewFail
  | SaveDigitalContractQuotaDiscount
  | SaveDigitalContractQuotaDiscountFail
  | SaveDigitalContractQuotaDiscountSuccess
  | RecalculateAddonPrice
  | RecalculateAddonPriceFail
  | RecalculateAddonPriceSuccess
  | RecalculateAddonsPrice
  | RecalculateAddonsPriceFail
  | RecalculateAddonsPriceSuccess
  | UpdateSubscription
  | UpdateSubscriptionFail
  | UpdateSubscriptionSuccess
  | CreateSubscription
  | CreateSubscriptionFail
  | CreateSubscriptionSuccess
  | CreateProduct
  | UpdateNextProduct
  | UpdateNextProductFail
  | UpdateNextProductSuccess
  | ResetProduct;
