<app-card class="free-features">
  <div class="free-features-listing">
    <app-card
      [type]="'success'"
      [borderStyle]="'none'"
      class="free-features-listing__features"
    >
      <div class="free-features-listing__header">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <b> {{ 'FREE_FEATURES_L' | translate }} </b>
          <app-badge [color]="'state-300'">{{
            'INCLUSIVE_L' | translate
          }}</app-badge>
        </div>
      </div>
      <div class="feature d-flex">
        <img src="/assets/images/icons/icon-check-blue.svg" />
        <p class="title-s free-features-listing__description">
          1 {{ 'AGENT_L' | translate }}
        </p>
      </div>
      <div class="feature d-flex">
        <img src="/assets/images/icons/icon-check-blue.svg" />
        <p class="title-s free-features-listing__description">
          {{ 'MANAGE_INVENTORY_L' | translate }}
        </p>
      </div>
      <div class="feature d-flex">
        <img src="/assets/images/icons/icon-check-blue.svg" />
        <p class="title-s free-features-listing__description">
          {{ 'TENANT_POOL_L' | translate }}
        </p>
      </div>
      <div class="feature d-flex">
        <img src="/assets/images/icons/icon-check-blue.svg" />
        <p class="title-s free-features-listing__description">
          {{ 'VISIT_CALENDAR_L' | translate }}
        </p>
      </div>
    </app-card>
  </div>

  <div class="free-features-listing__update-button">
    <ng-content></ng-content>
  </div>
</app-card>
