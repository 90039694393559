<div class="container p-0">
  <div class="subscription__content">
    @if (isTrial && !isAdmin) {
      <span class="subscription__trial_mode">{{
        'subscription.trial_mode_l' | translate
      }}</span>
    }
    @if (loaded) {
      @if (!loadingError) {
        <app-subscription-form
          [products]="products"
          [addons]="addons"
          [customer]="customer"
          [defaultProductId]="defaultProductId"
          [customerProduct]="customerProduct"
          [customerSize]="userData?.customer?.customerSize"
          [paymentMethod]="paymentMethod"
          [currency]="currency"
          [recalculatingPrice]="recalculatingPrices"
          [hasActiveProduct]="hasActiveProduct"
          [isTrial]="isTrial"
          [saving]="saving"
          (productChange)="onProductChange($event)"
          (updateSubscription)="onUpdate($event)"
          (createSubscription)="onCreate($event)"
          [form]="form"
          [isAdmin]="isAdmin"
          [nextProductId]="nextProductId"
          [addonDiscounts]="addonDiscounts"
          [digitalContractQuotaDiscounts]="digitalContractQuotaDiscounts"
          [digitalContractQuotaPackages]="digitalContractQuotaPackages"
          [objectContingentQuotaDiscounts]="objectContingentQuotaDiscounts"
          [objectContingentQuotaPackages]="objectContingentQuotaPackages"
          [recalculationActionState$]="recalculationActionState$"
          (discountChange)="onDiscountChange($event)"
          (saveQuotaDiscounts)="onSaveQuotaDiscounts($event)"
          (saveObjectContingentQuotaDiscounts)="
            onSaveObjectContingentQuotaDiscounts($event)
          "
          (createLandlord)="onCreateLandlord()"
        ></app-subscription-form>
      } @else {
        <app-message [messageType]="'error'">
          <div>{{ 'LOAD_DATA_ERROR_M' | translate }}</div>
        </app-message>
      }
    } @else {
      <div class="loading-area">
        <app-loading-spinner></app-loading-spinner>
      </div>
    }
  </div>
</div>
